import { React, useState, useEffect, useRef, useContext, Link, Fragment } from 'react';
//import { Link as RouterLink } from 'react-router-dom';

// @material-ui/core
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Select, MenuItem } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from 'axios';
import RowData from './RowData.js';
import RowDataDialog from './RowDataDialog';
import ExcelDialog from './ExcelDialog';
import BookManageContextProvider from './BookManageContext';
import { RootContext } from '../../RootContext';

import { Edit as EditIcon, Add as AddIcon, PlayCircleFilledWhiteTwoTone, SentimentVerySatisfiedSharp } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}));

const StyledSelectFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 130
}));

const StyledInputFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 250
}));

const StyledSearchFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1)
}));

const StyledDiv = styled('div')({
  '& tbody>.MuiTableRow-root:hover': {
    background: '#f5f6fa'
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cad8e3',
    color: theme.palette.common.black,
  }
}));

// const useStyles = makeStyles((theme) => ({
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//     //paddingLeft: theme.spacing(1),
//     //paddingRight: theme.spacing(1),
//   },
//   table: {
//     '& tbody>.MuiTableRow-root:hover': {
//       background: '#f5f6fa',
//     }
//   },
//   detailHead: {
//     '& > *': {
//       fontWeight: 'bold',
//       borderBottom: 'unset',
//     },
//   },
//   searchFormControl: {
//     margin: theme.spacing(1),
//   },
//   input: {
//     minWidth: 250,
//     margin: theme.spacing(1)
//   },

//   select: {
//     margin: theme.spacing(1),
//     minWidth: 130
//   },

//   addRow: {
//     float: 'right'
//   }
// }));



const BookManage = () => {
  const nullBookData = [];
  const columns = [
    // { title: "Id", field: "ID", render: rowData => <RouterLink to={rowData.ID}>{rowData.ID}</RouterLink> },
    // { title: '封面', field: 'imageUrl', render: rowData => <img src={rowData.AVATAR} style={{width: 40, borderRadius: '0%'}}/> },
    { title: "法藏編號", field: "category" },
    { title: "書名", field: "name" },
    // { title: "ISBN", field: "ISBN" },
    { title: "助印價", field: "price" },
    {
      title: "庫存量", field: "qty",
      cellStyle: (e, rowData) => {
        if (rowData.qty < rowData.safeQty) {
          return { color: "red", fontWeight: "bold" };
        }
      },
    },
    { title: "最低庫存量", field: "safeQty" },
    { title: "儲位", field: "lgort" },
  ];

  //const [data, setData] = useState(rawData);
  const [bookData, setBookData] = useState(nullBookData);
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const [rowDataDialogOpen, setRowDataDialogOpen] = useState(false);
  const [showSearchWord, setShowSearchWord] = useState(true);
  const [foundBenefactor, setFoundBenefactor] = useState(false);
  const [excelDialogOpen, setExcelDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  const [status, setStatus] = useState("");

  const {
    permissionAddBook,
    permissionViewSecretBook,
    permissionBookExcel
  } = useContext(RootContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setStatus(newValue);

    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData?start=' + page * rowsPerPage + '&cnt=' + rowsPerPage + "&id=" + window.sessionStorage.getItem('authId'),
      );
      setBookData(result.data.data);
      setTotalRows(result.data.total);
      setPage(0); // 切換tab，都要將分頁切回第一頁
    };

    const getData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLowerSafeQty?start=' + page * rowsPerPage + '&cnt=' + rowsPerPage + "&id=" + window.sessionStorage.getItem('authId')
      );
      setBookData(result.data.data);
      setTotalRows(result.data.total);
      setPage(0); // 切換tab，都要將分頁切回第一頁
    };

    const getIsPrivateData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetIsPrivate?start=' + page * rowsPerPage + '&cnt=' + rowsPerPage + "&id=" + window.sessionStorage.getItem('authId')
      );
      setBookData(result.data.data);
      setTotalRows(result.data.total);
      setPage(0); // 切換tab，都要將分頁切回第一頁
    };

    if (newValue === '不公開')
      getIsPrivateData();
    else if (newValue === '低於最低庫存量')
      getData();
    else
      fetchData();
  };

  // const handleSearchFieldChange = event => {
  //   if (event.target.value === 'category1') {
  //     searchWordRef.current.value = '';
  //     setShowSearchWord(false);
  //   }
  //   else
  //     setShowSearchWord(true);
  // };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData?start=' + page * rowsPerPage + '&cnt=' + rowsPerPage + "&id=" + window.sessionStorage.getItem('authId'),
      );
      setBookData(result.data.data);
      setTotalRows(result.data.total);
    };

    const getData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLowerSafeQty?start=' + page * rowsPerPage + '&cnt=' + rowsPerPage + "&id=" + window.sessionStorage.getItem('authId')
      );
      setBookData(result.data.data);
      setTotalRows(result.data.total);
    };

    const getIsPrivateData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetIsPrivate?start=' + page * rowsPerPage + '&cnt=' + rowsPerPage + "&id=" + window.sessionStorage.getItem('authId')
      );
      setBookData(result.data.data);
      setTotalRows(result.data.total);
    };

    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetSearch',
        {
          'searchField': searchFieldRef.current.value,
          'searchWord': searchWordRef.current.value,
          'start': page * rowsPerPage,
          'cnt': rowsPerPage,
          'id': window.sessionStorage.getItem('authId')
        },
      ).then(response => {
        if (response.data.succ) {
          setBookData(response.data.data);
          setTotalRows(response.data.total);

          if (searchFieldRef.current.value === 'benefactor')
            setFoundBenefactor(true);
          else
            setFoundBenefactor(false);
        }
      });
    };

    if (searchFieldRef.current.value === '書名' && searchWordRef.current.value === '' && status === '') // 搜尋欄為空時
      fetchData();
    else if (status == '不公開')
      getIsPrivateData();
    else if (status === '低於最低庫存量')
      getData();
    else
      postData();
  }, [isDataUpdate, page, rowsPerPage]);

  const searchFieldRef = useRef();
  const searchWordRef = useRef();

  const searchClick = () => {
    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetSearch',
        {
          'searchField': searchFieldRef.current.value,
          'searchWord': searchWordRef.current.value,
          'start': page * rowsPerPage,
          'cnt': rowsPerPage,
          'id': window.sessionStorage.getItem('authId')
        },
      ).then(response => {
        if (response.data.succ) {
          setBookData(response.data.data);
          setTotalRows(response.data.total);
          setPage(0);   // 重新搜尋，都要將分頁切回第一頁

          if (searchFieldRef.current.value === 'benefactor')
            setFoundBenefactor(true);
          else
            setFoundBenefactor(false);
        }
      });
    };
    postData();
    setStatus("");
  }

  const excelClick = () => {
    setExcelDialogOpen(!excelDialogOpen);
  }

  return (
    <StyledContainer maxWidth="lg">
      <StyledSelectFormControl variant="outlined">
        <InputLabel id="demo-simple-select-label">搜尋欄位</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          defaultValue='name'
          label="搜尋欄位"
          size="small"
          inputRef={searchFieldRef}
          //onChange={handleSearchFieldChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            // getContentAnchorEl: null
          }}
        >
          <MenuItem value={'category'}>類別_佛經</MenuItem>
          {permissionViewSecretBook ? <MenuItem value={'category1'}>類別_法本</MenuItem> : ''}
          <MenuItem value={'category2'}>類別_善書</MenuItem>
          <MenuItem value={'category3'}>類別_光碟</MenuItem>
          <MenuItem value={'category4'}>類別_教材</MenuItem>
          <MenuItem value={'name'}>書名</MenuItem>
          {/* <MenuItem value={'lgort'}>儲位</MenuItem> */}
        </Select>
      </StyledSelectFormControl>

      <StyledInputFormControl variant="outlined">
        <TextField
          label="搜尋關鍵字"
          size="small"
          inputRef={searchWordRef}
          disabled={showSearchWord ? false : true}
        >
        </TextField>
      </StyledInputFormControl>

      <StyledSearchFormControl>
        <Tooltip title="查詢">
          <IconButton color="primary" aria-label="upload picture" component="span"
            onClick={searchClick}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </StyledSearchFormControl>
      <StyledSearchFormControl>
        {permissionBookExcel ?
          <Button
            variant="outlined"
            onClick={excelClick}
          >
            匯出Excel
          </Button>
          : ''
        }
      </StyledSearchFormControl>
      {permissionAddBook ?
        <FormControl sx={{ float: 'right' }}>
          <Tooltip title="新增資料">
            <IconButton color="primary" component="span"
              onClick={() => setRowDataDialogOpen(!rowDataDialogOpen)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </FormControl>
        : ''
      }

      <BookManageContextProvider>
        <RowDataDialog
          dialogOpen={rowDataDialogOpen}
          title="新增法寶善書"
          buttonText="新增"
          onClose={() => setRowDataDialogOpen(!rowDataDialogOpen)}
          onUpdate={() => setIsDataUpdate(!isDataUpdate)}
        >
        </RowDataDialog>
        <ExcelDialog
          dialogOpen={excelDialogOpen}
          title="匯出Excel"
          buttonText="匯出"
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              setExcelDialogOpen(!excelDialogOpen);
            }
          }}>
        </ExcelDialog>
      </BookManageContextProvider>

      <Box sx={{ width: '100%' }}>
        <Tabs
          value={status}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab value="" label="全部" />
          <Tab value="低於最低庫存量" label="低於最低庫存量" />
          <Tab value="不公開" label="不公開" />
        </Tabs>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <StyledDiv>
              <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                <Table stickyHeader size="small" aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell />
                      <StyledTableCell>道場別</StyledTableCell>
                      <StyledTableCell>類別/編號</StyledTableCell>
                      <StyledTableCell style={{ width: '25rem' }}>書名</StyledTableCell>
                      <StyledTableCell align="right">庫存量</StyledTableCell>
                      <StyledTableCell align="right">最低庫存量</StyledTableCell>
                      <StyledTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <BookManageContextProvider>
                      {bookData.map((row) => (
                        <RowData key={row.id} row={row}
                          onUpdate={() => setIsDataUpdate(!isDataUpdate)}
                          isBenefactorColor={foundBenefactor}
                          benefactorName={searchWordRef.current.value}
                        />
                      ))}
                    </BookManageContextProvider>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='每頁顯示筆數'
                showFirstButton={true}
                showLastButton={true}
              />
            </StyledDiv>
          </Paper>
        </Grid>
      </Grid>
    </StyledContainer >
  )
}

export default BookManage