import React, { useEffect, useState } from 'react';

export const RootContext = React.createContext();
const RootContextProvider = ({ children }) => {
  const prevAuth = window.sessionStorage.getItem('auth') || false;
  const prevAuthBody = window.sessionStorage.getItem('authBody') || null;

  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [authId, setAuthId] = useState(prevAuthBody);
  const [authName, setAuthName] = useState(prevAuthBody);
  const [permissionViewBook, setPermissionViewBook] = useState(0);
  const [permissionEditBook, setPermissionEditBook] = useState(0);
  const [permissionAddBook, setPermissionAddBook] = useState(0);
  const [permissionViewSecretBook, setPermissionViewSecretBook] = useState(0);
  const [permissionBookExcel, setPermissionBookExcel] = useState(0);

  const [permissionViewPurchase, setPermissionViewPurchase] = useState(0);
  const [permissionAddPurchase, setPermissionAddPurchase] = useState(0);
  const [permissionEditPartOfPurchase, setPermissionEditPartOfPurchase] = useState(0);
  const [permissionClosePurchase, setPermissionClosePurchase] = useState(0);
  const [permissionPurchaseExcel, setPermissionPurchaseExcel] = useState(0);

  const [permissionViewShipment, setPermissionViewShipment] = useState(0);
  const [permissionAddShipment, setPermissionAddShipment] = useState(0);
  const [permissionEditPartOfShipment, setPermissionEditPartOfShipment] = useState(0);
  const [permissionCloseShipment, setPermissionCloseShipment] = useState(0);
  const [permissionShipmentExcel, setPermissionShipmentExcel] = useState(0);

  const [permissionReport, setPermissionReport] = useState(0);
  const [updateListItem, setUpdateListItem] = useState(true);

  useEffect(
    () => {
      // window.localStorage.setItem('authenticated', authenticated);
      // window.localStorage.setItem('authId', authId);
      // window.localStorage.setItem('authName', authName);
      window.sessionStorage.setItem('authenticated', authenticated);
      window.sessionStorage.setItem('authId', authId);
      window.sessionStorage.setItem('authName', authName);
    },
    [authenticated, authName]
  );
  const defaultContext = {
    authenticated,
    setAuthenticated,
    authId,
    setAuthId,
    authName,
    setAuthName,

    permissionViewBook,
    setPermissionViewBook,
    permissionEditBook,
    setPermissionEditBook,
    permissionAddBook,
    setPermissionAddBook,
    permissionViewSecretBook,
    setPermissionViewSecretBook,
    permissionBookExcel,
    setPermissionBookExcel,

    permissionViewPurchase,
    setPermissionViewPurchase,
    permissionAddPurchase,
    setPermissionAddPurchase,
    permissionEditPartOfPurchase,
    setPermissionEditPartOfPurchase,
    permissionClosePurchase,
    setPermissionClosePurchase,
    permissionPurchaseExcel,
    setPermissionPurchaseExcel, 

    permissionViewShipment,
    setPermissionViewShipment,
    permissionAddShipment,
    setPermissionAddShipment,
    permissionEditPartOfShipment,
    setPermissionEditPartOfShipment,
    permissionCloseShipment,
    setPermissionCloseShipment,
    permissionShipmentExcel,
    setPermissionShipmentExcel,

    permissionReport,
    setPermissionReport,         
    updateListItem,          // 用來觸發更新進/出貨管理的待處理案數
    setUpdateListItem
  };

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
};

export default RootContextProvider;