import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const OrderContext = React.createContext();
const OrderContextProvider = ({ children }) => {
  const contextEmptyOrder = [
    {
      allField: '', // name;benefactor;category;isbn 合併欄位以做連動，在DynamicRows.js會用到
      category: '',
      id: '',
      bookId: '',
      qty: '0'
    }
  ];

  const nullBookData = [];
  //const [contextDynamicData, setContextDynamicData] = useState(contextEmptyOrder); // 預設新增一筆空的
  const [contextDynamicData, setContextDynamicData] = useState(nullBookData);
  const [contextRowDataId, setContextRowDataId] = useState("");


  const defaultContext = {
    contextEmptyOrder,
    // 新增動態書目用
    contextDynamicData,
    setContextDynamicData,
    // 當進/出貨按編輯後，傳入row id並撈取書目資料
    contextRowDataId,
    setContextRowDataId,
  };
  

  return (
    <OrderContext.Provider value={defaultContext}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;