import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const ShipmentContext = React.createContext();
const ShipmentContextProvider = ({ children }) => {
  const emptyShipment = [
    {
      allField: '', // name;benefactor;category;isbn 合併欄位以做連動，在DynamicRows.js會用到
      id: '',
      bookId: '',
      bookItemId: '',
      category: '',
      benefactor: '',
      lgort: '',
      qty: 0
    }
  ];

  const nullBookData = [];
  const [dynamicData, setDynamicData] = useState(emptyShipment);
  const [rowDataId, setRowDataId] = useState("");
  const [locationList, setLocationList] = useState(nullBookData);
  const [location, setLocation] = useState('');

  useEffect(() => {
    const fetchLocationList = async (id) => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLocation',
      );
      setLocationList(result.data.data);
    };
    fetchLocationList();
  }, []);

  const defaultContext = {
    emptyShipment,
    // 建立進/出貨單時，新增動態書目用
    dynamicData,
    setDynamicData,
    // 當進/出貨按編輯後，傳入row id並撈取書目資料
    rowDataId,
    setRowDataId,
    location,
    setLocation,
    locationList,
    setLocationList
  };

  return (
    <ShipmentContext.Provider value={defaultContext}>
      {children}
    </ShipmentContext.Provider>
  );
};

export default ShipmentContextProvider;