import React, { Fragment, useState, useEffect, useContext, useMemo, useCallback, useRef } from "react";
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableCell, TableHead, TableRow, ButtonGroup, FormControl, FormHelperText } from '@mui/material';
import { Button, TextField, Select, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { OrderContext } from './OrderContext';
import Simplert from "../../components/Simplert";
import { Add as AddIcon } from "@mui/icons-material";

const StyledTableHead = styled(TableHead)({
  backgroundColor: '#ebd3a7'
});

// const useStyles = makeStyles((theme) => ({
//   root: {
//     color: 'black',
//   },
//   popper: {
//     width: "fit-content"
//   },
//   tableHead: {
//     backgroundColor: '#cad8e3'
//   },
// }));

const DynamicRows = () => {
  const {
    contextDynamicData,
    setContextDynamicData
  } = useContext(OrderContext);

  const nullBookData = [];
  const [bookData, setBookData] = useState(nullBookData);
  const [benefactorData, setBenefactorData] = useState(nullBookData);
  const [lgortData, setLgortData] = useState(nullBookData);
  const [tmpBenefactor, setTmpBenefactor] = useState([]);


  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const refArray = useRef([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await axios.get(
  //       process.env.REACT_APP_API_ENDPOINT + '/api/Order/GetBookData',
  //     ).then((rtn) => {
  //       setBookData(rtn.data.data);

  //       // 撈dynamicData之前，必須先將bookData撈出來，否則select在assign value時，會對應不到正確的MenuItem
  //       // 因此要寫在axios.get.then()之中
  //       // if (rowDataId !== "") {
  //       //   fetchBookItemData(rowDataId);
  //       // }

  //     });
  //   };
  //   fetchData();
  // }, []);


  const handleQtyChange = event => {
    const _tmpRows = [...contextDynamicData];
    _tmpRows[event.target.dataset.id][event.target.name] = event.target.value;
    setContextDynamicData(_tmpRows);
  };





  /*********************動態取得書名清單************************\
  * 選類別後，動態取得書名清單
  \************************************************************/
  const nullBook = { book: null };

  // 二維陣列，用於儲存每筆書目獨立的書名
  const [dynamicBook, setDynamicBook] = useState(Array.from({ length: 150 }, () => Array.from({ length: 1 }, () => nullBook)));

  // 功能1: 用於dialog open初始化, 載入書目清單中的每一筆書目所對應的功徳主
  // 功能2: 於挑選書目後做功徳主的連動
  const fetchBookData = async (dynamicDataIndex, categoryArrayIndex, categoryArray, currDynamicBook) => {
    const result = await axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/Order/GetBookData?mode=' + categoryArray[categoryArrayIndex],
    ).then((rtn) => {
      let copy = [...currDynamicBook];
      let tmpIndex = 0;
      if (categoryArray.length === 1)  // 用於新增書目時
        tmpIndex = dynamicDataIndex;
      else                           // 用於dialog open初始化
        tmpIndex = categoryArrayIndex;
      copy[tmpIndex] = Array.from({ length: rtn.data.data.length }, () => nullBook);
      for (var i = 0; i < rtn.data.data.length; i++) {
        copy[tmpIndex][i] = { id: rtn.data.data[i].id, name: rtn.data.data[i].name };
      }
      setDynamicBook(copy);

      // 用於dialog open初始化
      if (categoryArray.length > categoryArrayIndex + 1)
        fetchBookData(dynamicDataIndex, categoryArrayIndex + 1, categoryArray, copy); // 遞迴呼叫
    });
  };

  const handleCategorySelectChange = (index) => event => {
    const _tmpRows = [...contextDynamicData];
    _tmpRows[index]['category'] = event.target.value;
    setContextDynamicData(_tmpRows);

    let _tmpBookIdArray = [event.target.value];
    fetchBookData(index, 0, _tmpBookIdArray, dynamicBook);
  };





  const handleSelectChange = (index) => event => {
    const _tmpRows = [...contextDynamicData];
    let tmp = event.target.value.split(';');
    //_tmpRows[index]['allField'] = event.target.value;     // 合併欄位
    _tmpRows[index]['bookId'] = tmp[0];                   // book id
    setContextDynamicData(_tmpRows);
  };

  const handleAddRow = () => {
    setContextDynamicData(prevData => [...prevData, { allField: '', category: '', id: '', bookId: '', qty: '0' }]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const _tmpRows = [...contextDynamicData];
    _tmpRows.splice(idx, 1);
    setContextDynamicData(_tmpRows);
  }

  return (
    <Fragment>
      <div className="container">
        <div className="row clearfix">
          <div className="col-md-12 column">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <StyledTableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>類別</TableCell>
                    <TableCell>書籍名稱</TableCell>
                    <TableCell>冊數</TableCell>
                    <TableCell />
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {contextDynamicData.map((item, index) => (
                    <TableRow id="addr0" key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <FormControl variant="standard">
                          <Select
                            value={item.category}
                            name='category'
                            onChange={handleCategorySelectChange(index)}
                            autoWidth
                            displayEmpty
                          >
                            <MenuItem disabled value="">
                              <em>請選擇</em>
                            </MenuItem>
                            <MenuItem key="c1" value="0">佛經</MenuItem>
                            <MenuItem key="c2" value="1">善書</MenuItem>
                            <MenuItem key="c3" value="2">光碟</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl variant="standard">
                          <Select
                            //value={item.allField}
                            value={item.bookId}
                            name="name"
                            onChange={handleSelectChange(index)}
                            autoWidth
                            displayEmpty
                          // renderValue={(selected) => {
                          //   if (selected.length === 0) {
                          //     return <em>請選擇</em>;
                          //   } else {
                          //     let tmp = selected.split(';');
                          //     return tmp[2] + ' ' + tmp[1];
                          //   }

                          // }}
                          >
                            <MenuItem disabled value="">
                              <em>請選擇</em>
                            </MenuItem>
                            {
                              // bookData.map((bookDataItem, i) => (
                              //   // 為了取到功德主，所以將兩個欄位值合併在一起當value
                              //   <MenuItem key={i}
                              //     //value={bookDataItem.id + ';' + bookDataItem.name + ';' + bookDataItem.category + ';' + bookDataItem.isbn}
                              //     value={bookDataItem.id}
                              //   >
                              //     {bookDataItem.name}
                              //   </MenuItem>
                              // ))
                              dynamicBook[index].map((bookDataItem, i) =>
                                <MenuItem key={i} value={bookDataItem.id}>
                                  {bookDataItem.name}
                                </MenuItem>
                              )
                            }
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField value={item.qty}
                          sx={{ m: 1, minWidth: 70 }}
                          type="number"
                          name="qty"
                          placeholder='必填'
                          // inputProps={{ readOnly: true, 'data-id': index }}
                          inputProps={{ 'data-id': index }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleQtyChange}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={handleRemoveSpecificRow(index)}
                        >
                          刪除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button variant="contained" sx={{marginTop: 1, backgroundColor: '#4976d1' }} startIcon={<AddIcon />} onClick={handleAddRow}>
              新增項目
            </Button>
            {/* <Button
            onClick={handleRemoveRow}
            className="btn btn-danger float-right"
          >
              Delete Last Row
          </Button> */}

            <Simplert
              showSimplert={showAlert}
              type={alertType}
              title={alertTitle}
              message={alertMsg}
              customCloseBtnText='關閉'
              onClose={() => setShowAlert(!showAlert)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DynamicRows;
