import { React, useState, useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import clsx from 'clsx';

import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useHistory } from 'react-router-dom'
import { RootContext } from './RootContext';

import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import axios from 'axios';
import { MainListItems, secondaryListItems } from './listItems';
import ProfileDialog from './views/Account/ProfileDialog';
import BookManage from './views/BookManage';
import Purchase from './views/Purchase';
import Shipment from './views/Shipment';
import Report from './views/Report';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link> */}
      {' 2022.'}
      {/* {new Date().getFullYear()} */}
    </Typography>
  );
}

const drawerWidth = 240;

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  background: '#bd7511',
  ...theme.mixins.toolbar,
}));

const StyledMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);
// 要放在Layout Component外, 否則放裡面,當Layout render後, AppBar會被remount, 進而導致<AppBar />內的所有componet anchorEl reference會失效
// 失效後, IconButton(AccountCircleIcon)點選後, menu的位置會錯亂
const StyledAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    background: '#125887',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

const StyleDrawerHeaderDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   toolbar: {
//     paddingRight: 24, // keep right padding when drawer closed
//   },
//   toolbarIcon: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     padding: '0 8px',
//     background: '#bd7511',
//     ...theme.mixins.toolbar,
//   },
//   appBar: {
//     //background: '#2E3B55',
//     background: '#125887',
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   appBarShift: {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   menuButton: {
//     marginRight: 36,

//   },
//   menuButtonHidden: {
//     display: 'none',
//   },
//   title: {
//     flexGrow: 1,
//   },
//   drawerPaper: {
//     position: 'relative',
//     whiteSpace: 'nowrap',
//     background: '#b86706',
//     color: '#f7e2c3',
//     width: drawerWidth,
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   drawerPaperClose: {
//     overflowX: 'hidden',
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     width: theme.spacing(7),
//     [theme.breakpoints.up('sm')]: {
//       width: theme.spacing(9),
//     },
//   },
//   appBarSpacer: theme.mixins.toolbar,
//   content: {
//     flexGrow: 1,
//     height: '100vh',
//     overflow: 'auto',
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//   },
//   fixedHeight: {
//     height: 240,
//   },
// }));

export default function Layout() {
  const [open, setOpen] = useState(true);
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const nullData = [];
  const [profileData, setProfileData] = useState(nullData);
  const [isDataUpdate, setIsDataUpdate] = useState(false);

  let history = useHistory();
  const {
    authenticated,
    setAuthenticated,
    authId,
    authName
  } = useContext(RootContext);

  // 登出後，轉址去登入頁面
  useEffect(() => {
    if (!authenticated) {
      history.push("/PrivateLogin108");
    }
  }, [authenticated, history])

  useEffect(() => {
    const getData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/Account?id=' + authId,
      ).then(response => {
        if (response.data.succ) {
          setProfileData(response.data.data);
        }
      });
    };
    getData();
  }, [isDataUpdate]);


  const open1 = Boolean(anchorEl);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    handleClose();

    const getData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/Account?id=' + authId,
      ).then(response => {
        if (response.data.succ) {
          setProfileData(response.data.data);
        }
      });
    };
    getData();

    setProfileDialogOpen(!profileDialogOpen);
  }

  const handleLogout = (e) => {
    e.preventDefault();
    setAuthenticated(false);
  };

  return (
    <StyledEngineProvider injectFirst>
      <div style={{display: 'flex'}}>
        <CssBaseline />
        {/* <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}> */}
        <StyledAppBar position="fixed" open={open}>
          <Toolbar sx={{paddingRight: 24}}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              // className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>
              印贈經會管理系統
            </Typography>
            {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Typography component="h1" color="inherit" noWrap>
              {authName}
            </Typography>
            {auth && (
              <div>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  //getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open1}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleEditProfile}>個人資料</MenuItem>
                  <MenuItem onClick={handleLogout}>登出</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </StyledAppBar>
        <Drawer
          // variant="permanent"
          // classes={{
          //   paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          // }}
          variant="persistent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              background: '#b86706',
              color: '#f7e2c3',
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          open={open}
        >
          <StyledDiv>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </StyledDiv>
          <Divider />
          <List>
            <MainListItems />
          </List>
          {/* <Divider />
        <List>{secondaryListItems}</List> */}
        </Drawer>
        {/* <main className={classes.content}> */}
        <StyledMain open={open}>
          {/* <div className={classes.appBarSpacer} /> */}
          <StyleDrawerHeaderDiv />
          <Switch>
            <Route exact path='/PrivateHome108' component={BookManage} />
            <Route exact path='/PrivatePurchase108' component={Purchase} />
            <Route exact path='/PrivateShipment108' component={Shipment} />
            <Route exact path='/PrivateReport108' component={Report} />
            <Route path='/' component={BookManage} />
          </Switch>

          {/* <Box pt={4}>
            <Copyright />
          </Box> */}
        </StyledMain>
      </div>

      <ProfileDialog
        dialogOpen={profileDialogOpen}
        profile={profileData[0]}
        title="編輯個人資料"
        buttonText="儲存"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setProfileDialogOpen(!profileDialogOpen);
          }
        }}
        onUpdate={() => setIsDataUpdate(!isDataUpdate)}
      >
      </ProfileDialog>
    </StyledEngineProvider>
  );
}