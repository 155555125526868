import { React, useState, useContext, useEffect, useRef, Link, Fragment } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Button, TextField, Select, Menu, MenuItem } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import zIndex from '@mui/material/styles/zIndex';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}));

const StyledInputFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 250
}));

const StyledSearchFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1)
}));

const StyledDiv = styled('div')({
  '& tbody>.MuiTableRow-root:hover': {
    background: '#f5f6fa',
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cad8e3',
    color: theme.palette.common.black,
  }
}));

const DetailSpan = styled('span')({
  position: 'absolute',
  transform: 'translateY(70%) scale(0)',
  top: '0',
  display: 'inline',
  background: 'white',
  zIndex: 20,
  minWidth: '100%',
  padding: '1rem',
  border: '1px solid black'
});



const Report = () => {
  const nullData = [];
  const [monthData, setMonthtData] = useState(nullData);
  const [reportData, setReportData] = useState(nullData);
  const [showActionId, setShowActionId] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl_1, setAnchorEl_1] = useState(null);
  const [anchorEl_2, setAnchorEl_2] = useState(null);
  const [anchorEl_3, setAnchorEl_3] = useState(null);
  const [anchorEl_4, setAnchorEl_4] = useState(null);

  const searchWordRef = useRef();

  useEffect(() => {
    // const fetchMonth = async () => {
    //   const result = await axios.get(
    //     process.env.REACT_APP_API_ENDPOINT + '/api/Report/GetMonth?year=2022',
    //   );
    //   setMonthtData(result.data.data);
    // };

    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/Report?year=' + new Date().getFullYear(),
      );
      setReportData(result.data.data);
    };
    //fetchMonth();
    fetchData();
  }, []);

  const searchClick = () => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/Report?year=' + searchWordRef.current.value,
      );
      setReportData(result.data.data);
    };
    fetchData();
  }



  return (
    <StyledContainer maxWidth="lg" >
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <StyledInputFormControl variant="outlined">
            <TextField
              //label="搜尋西年元"
              placeholder="搜尋西元年"
              size="small"
              inputRef={searchWordRef}
            >
            </TextField>
          </StyledInputFormControl>
          <StyledSearchFormControl>
            <Tooltip title="查詢">
              <IconButton color="primary" aria-label="upload picture" component="span"
                onClick={searchClick}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </StyledSearchFormControl>
        </Grid>
        {reportData.map((lv1) => (
          lv1.items.map((lv2) => (
            <Grid key={lv2.month} item xs={3}>
              <Paper
                sx={{
                  height: 321,
                  width: 230,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <StyledDiv>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>{lv2.month}月份</StyledTableCell>
                        <StyledTableCell>合計</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{ position: 'relative' }}
                        onMouseEnter={(event) => {
                          setShowActionId(lv2.month);

                          if (anchorEl !== event.currentTarget)
                            setAnchorEl(event.currentTarget);
                        }}
                        onMouseLeave={() => {
                          setAnchorEl(null);
                          setShowActionId(-1);
                        }}
                      >
                        <TableCell>
                          索取總件數
                          {showActionId === lv2.month ?
                            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
                              <Box sx={{ fontSize: 10, border: 0, p: 1, bgcolor: 'background.paper' }}>
                                <TableContainer component={Paper} sx={{ maxHeight: 400, border: 2, borderColor: '#a6cded', borderCollapse: 'collapse' }}>
                                  <Table size="small">
                                    <TableBody>
                                      {lv2.shipmentItems.map((lv3) => (
                                        <TableRow>
                                          <TableCell>{lv3.region}</TableCell>
                                          <TableCell>{lv3.qty}件</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Popper> : ''
                          }
                        </TableCell>
                        <TableCell>{lv2.shipmentCount}件</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ position: 'relative' }}
                        onMouseEnter={(event) => {
                          setShowActionId(lv2.month);

                          if (anchorEl_1 !== event.currentTarget)
                            setAnchorEl_1(event.currentTarget);
                        }}
                        onMouseLeave={() => {
                          setAnchorEl_1(null);
                          setShowActionId(-1);
                        }}
                      >
                        <TableCell>
                          郵資費用
                          {showActionId === lv2.month ?
                            <Popper open={Boolean(anchorEl_1)} anchorEl={anchorEl_1}>
                              <Box sx={{ fontSize: 10, border: 0, p: 1, bgcolor: 'background.paper' }}>
                                <TableContainer component={Paper} sx={{ maxHeight: 400, border: 2, borderColor: '#a6cded', borderCollapse: 'collapse' }}>
                                  <Table size="small">
                                    <TableBody>
                                      {lv2.shipmentItems.map((lv3) => (
                                        <TableRow>
                                          <TableCell>{lv3.region1}</TableCell>
                                          <TableCell>{lv3.cost}元</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Popper> : ''
                          }
                        </TableCell>
                        <TableCell>{lv2.costSum}元</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ position: 'relative' }}
                        onMouseEnter={(event) => {
                          setShowActionId(lv2.month);

                          if (anchorEl_2 !== event.currentTarget)
                            setAnchorEl_2(event.currentTarget);
                        }}
                        onMouseLeave={() => {
                          setAnchorEl_2(null);
                          setShowActionId(-1);
                        }}
                      >
                        <TableCell>
                          經本索取總數
                          {showActionId === lv2.month ?
                            <Popper open={Boolean(anchorEl_2)} anchorEl={anchorEl_2}>
                              <Box sx={{ fontSize: 10, border: 0, p: 1, bgcolor: 'background.paper' }}>
                                <TableContainer component={Paper} sx={{ maxHeight: 400, border: 2, borderColor: '#a6cded', borderCollapse: 'collapse' }}>
                                  <Table size="small">
                                    <TableBody>
                                      {lv2.buddhaItems.map((lv3) => (
                                        <TableRow>
                                          <TableCell>{lv3.name}</TableCell>
                                          <TableCell>{lv3.qty}本</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Popper> : ''
                          }
                        </TableCell>
                        <TableCell>{lv2.buddhaTotalQty}本</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ position: 'relative' }}
                        onMouseEnter={(event) => {
                          setShowActionId(lv2.month);

                          if (anchorEl_3 !== event.currentTarget)
                            setAnchorEl_3(event.currentTarget);
                        }}
                        onMouseLeave={() => {
                          setAnchorEl_3(null);
                          setShowActionId(-1);
                        }}
                      >
                        <TableCell>
                          善書索取總數
                          {showActionId === lv2.month ?
                            <Popper open={Boolean(anchorEl_3)} anchorEl={anchorEl_3}>
                              <Box sx={{ fontSize: 10, border: 0, p: 1, bgcolor: 'background.paper' }}>
                                <TableContainer component={Paper} sx={{ maxHeight: 400, border: 2, borderColor: '#a6cded', borderCollapse: 'collapse' }}>
                                  <Table size="small">
                                    <TableBody>
                                      {lv2.goodItems.map((lv3) => (
                                        <TableRow>
                                          <TableCell>{lv3.name}</TableCell>
                                          <TableCell>{lv3.qty}本</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Popper> : ''
                          }
                        </TableCell>
                        <TableCell>{lv2.goodTotalQty}本</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ position: 'relative' }}
                        onMouseEnter={(event) => {
                          setShowActionId(lv2.month);

                          if (anchorEl_4 !== event.currentTarget)
                            setAnchorEl_4(event.currentTarget);
                        }}
                        onMouseLeave={() => {
                          setAnchorEl_4(null);
                          setShowActionId(-1);
                        }}
                      >
                        <TableCell>
                          光碟索取總數
                          {showActionId === lv2.month ?
                            <Popper open={Boolean(anchorEl_4)} anchorEl={anchorEl_4}>
                              <Box sx={{ fontSize: 10, border: 0, p: 1, bgcolor: 'background.paper' }}>
                                <TableContainer component={Paper} sx={{ maxHeight: 400, border: 2, borderColor: '#a6cded', borderCollapse: 'collapse' }}>
                                  <Table size="small">
                                    <TableBody>
                                      {lv2.discItems.map((lv3) => (
                                        <TableRow>
                                          <TableCell>{lv3.name}</TableCell>
                                          <TableCell>{lv3.qty}片</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Popper> : ''
                          }
                        </TableCell>
                        <TableCell>{lv2.discTotalQty}片</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyledDiv>
              </Paper>
            </Grid>
          ))))}
      </Grid>
    </StyledContainer >
  );
}


export default Report;