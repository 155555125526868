import { React, useState, useEffect, useRef, Fragment, useContext } from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import { FormControl, InputAdornment } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Button, TextField, Select, MenuItem, InputLabel } from "@mui/material";
import axios from 'axios';
import { RootContext } from '../../RootContext';
import { PurchaseContext } from './PurchaseContext';
import Simplert from "../../components/Simplert";

import SaveIcon from '@mui/icons-material/Save';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DynamicRows from "./DynamicRows";

const StyledDialogTitle = styled(DialogTitle)({
  background: '#125887',
  color: 'white'
});

const StyledSubmitDialogTitle = styled(DialogTitle)({
  background: '#125887',
  color: 'white'
});

const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset'
  }
});

// const useStyles = makeStyles({
//   title: {
//     background: '#125887',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   formStyle: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
//   submit_confirm_title: {
//     background: '#125887',
//     '& h2': {
//       color: 'white',
//     }
//   },
// });

const RowDataDialog = (props) => {
  const {
    dialogOpen = false,
    row = [],
    onClose = () => { },
    onUpdate = () => { },
    title = "My Dialog",
    buttonText = "Save"
  } = props;

  const {
    authId,
    authName,
    updateListItem,
    setUpdateListItem,
    permissionClosePurchase,
    permissionEditPartOfPurchase
  } = useContext(RootContext);

  const {
    emptyPurchase,
    dynamicData,
    setDynamicData,
    locationList,
    location,
    setLocation
  } = useContext(PurchaseContext);

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertForVerified, setShowAlertForVerified] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const [verifiedConfirmOpen, setVerifiedConfirmOpen] = useState(false);
  const [btnSaveText, setBtnSaveText] = useState(buttonText);
  const [rowId, setRowId] = useState(row.id);

  const createDateRef = useRef();
  const creatorRef = useRef();
  const receiverRef = useRef();
  const sourceRef = useRef();
  const remarkRef = useRef();

  useEffect(() => {
    if (dialogOpen) {
      setLocation(row.location);
    }

  }, [dialogOpen]);

  const handleAddNewRow = () => {
    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Purchase/',
        {
          'createDate': createDateRef.current.value,
          'creator': creatorRef.current.value,
          'creatorId': authId,
          'receiver': receiverRef.current.value,
          'location': location,
          'source': sourceRef.current.value,
          'remark': remarkRef.current.value,
          'subItem': dynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('建立成功');
          setAlertMsg("");
          onUpdate();
          setBtnSaveText("儲存");
          setRowId(response.data.message); // 接收剛新增的id
          // 觸發更新進貨管理待處理的案件數
          setUpdateListItem(!updateListItem);
        }
        setShowAlert(true);
      });
    };
    postData();
  };

  const handleUpdateRow = () => {
    const putData = async () => {
      const result = await axios.put(
        process.env.REACT_APP_API_ENDPOINT + '/api/Purchase/' + rowId,
        {
          'createDate': createDateRef.current.value,
          'creator': creatorRef.current.value,
          'creatorId': authId,
          'location': location,
          'source': sourceRef.current.value,
          'receiver': receiverRef.current.value,
          'remark': remarkRef.current.value,
          'subItem': dynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('儲存成功');
          setAlertMsg("");
          onUpdate();
        }
        setShowAlert(true);
      });
    };
    putData();
  };

  const handleVerified = (id) => {
    const submitData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Purchase/Verified',
        {
          'id': id,
          'subItem': dynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('結案成功');
          setAlertMsg(response.data.message);
          // 觸發更新進貨管理待處理的案件數
          setUpdateListItem(!updateListItem);
        }
        setShowAlertForVerified(true);
      });
    };
    submitData();
  };

  const popupVerifiedConfirm = () => {
    setVerifiedConfirmOpen(true);
  }

  const handleVerifiedConfirmClose = () => {
    if (showAlertForVerified)
      onUpdate();

    setVerifiedConfirmOpen(false);
    setShowAlertForVerified(false);

    if (alertTitle === '結案成功')
      handleCloseClick();
  };
  const handleCloseClick = () => {
    // 按取消後，必須先將dynamicData清空回原始狀態，否則會出錯
    setDynamicData(emptyPurchase);
    if (buttonText === '建立')
      setBtnSaveText('建立');
    onClose();
  }

  const handleLocationSelectChange = event => {
    setLocation(event.target.value);
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '/' + mm + '/' + dd;

  return (
    <Fragment>
      <Dialog onClose={onClose} open={dialogOpen} fullWidth maxWidth="lg">
        <StyledDialogTitle>
          {title}
        </StyledDialogTitle>
        <Paper sx={{ padding: "2em" }}>
          <Table size="small">
            <TableBody>
              <StyledTableRow>
                <TableCell width="15%">
                  <FormControl variant="standard" fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      道場別
                    </InputLabel>
                    <Select
                      value={location || ""}
                      name="location"
                      onChange={handleLocationSelectChange}
                      inputProps={{ readOnly: permissionEditPartOfPurchase ? true : false }}
                    >
                      {locationList.map((item, i) =>
                        <MenuItem key={i} value={item}>
                          {item}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField defaultValue={btnSaveText === '建立' ? today : row.createDate} label="建立日期"
                    //onInput={(e) => setDlgCategory(e.target.value)}
                    inputRef={createDateRef}
                    InputProps={{ readOnly: true }}
                    variant="standard"
                  />
                </TableCell>
                <TableCell>
                  <TextField defaultValue={btnSaveText === '建立' ? authName : row.creator} label="建立者"
                    inputRef={creatorRef}
                    InputProps={{ readOnly: true }}
                    variant="standard"
                  />
                </TableCell>
                <TableCell>
                  <TextField label="進貨來源"
                    defaultValue={row.source}
                    inputRef={sourceRef}
                    InputProps={{ readOnly: permissionEditPartOfPurchase ? true : false }}
                    variant="standard"
                  />
                </TableCell>
                <TableCell>
                  <TextField label="收貨人"
                    defaultValue={row.receiver}
                    inputRef={receiverRef}
                    variant="standard"
                    color={permissionEditPartOfPurchase ? "error" : "primary"}
                    focused={permissionEditPartOfPurchase ? true : false}
                  />
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell colSpan={5}>
                  <FormControl fullWidth>
                    <TextField defaultValue={row.remark} label="備註"
                      inputRef={remarkRef}
                      InputProps={{ readOnly: permissionEditPartOfPurchase ? true : false }}
                      variant="standard"
                    />
                  </FormControl>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell colSpan={5}>
                  <DynamicRows />
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <div style={{ float: 'right' }}>
            <Box
              sx={{
                '& button': {
                  m: 1,
                },
              }}
            >
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={btnSaveText === '建立' ? handleAddNewRow : handleUpdateRow}
              >
                {btnSaveText}
              </Button>
              {(btnSaveText === '建立' || row.status === '結案') ? '' :
                permissionClosePurchase ?
                  <Button
                    variant="outlined"
                    startIcon={<AssignmentTurnedInIcon />}
                    onClick={popupVerifiedConfirm}
                  >
                    結案
                  </Button>
                  : ''
              }
              <Button variant="outlined" onClick={handleCloseClick}>取消</Button>
            </Box>
          </div>

          <Simplert
            showSimplert={showAlert}
            type={alertType}
            title={alertTitle}
            message={alertMsg}
            customCloseBtnText='關閉'
            //onClose={() => setShowAlert(!showAlert)}
            onClose={() => {
              if (alertType === "success") {
                if (buttonText !== '建立')
                  handleCloseClick();
                setShowAlert(!showAlert);
              }
              else
                setShowAlert(!showAlert);
            }}
          />
        </Paper>
      </Dialog>

      <Dialog
        open={verifiedConfirmOpen}
        //onClose={handleVerifiedConfirmClose}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleVerifiedConfirmClose();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledSubmitDialogTitle id="alert-dialog-title">
          {"結案"}
        </StyledSubmitDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            確定要結案嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<AssignmentTurnedInIcon />}
            variant="outlined" onClick={() => handleVerified(rowId)}>
            確定
          </Button>
          <Button variant="outlined" onClick={handleVerifiedConfirmClose}>取消</Button>
        </DialogActions>

        <Simplert
          showSimplert={showAlertForVerified}
          type={alertType}
          title={alertTitle}
          message={alertMsg}
          customCloseBtnText='關閉'
          onClose={handleVerifiedConfirmClose}
        />
      </Dialog>
    </Fragment>
  );
}

export default RowDataDialog;