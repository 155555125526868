import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { RootContext } from './RootContext';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import StorageIcon from '@mui/icons-material/Storage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Fragment } from 'react';

function ListItemLink(props) {
  const { icon, primary, queueCnt, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <div style={{ display: 'flex' }}>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
        {queueCnt > 0 ?
          <Typography variant="body1">
            {queueCnt}
          </Typography> :
          null}
      </ListItem>
    </div>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

var aa = 10;

const MainListItems = () => {
  const [purchaseQueueCnt, setPurchaseQueueCnt] = useState(0);
  const [shipmentQueueCnt, setShipmentQueueCnt] = useState(0);

  const {
    updateListItem,
    permissionViewBook,
    permissionViewPurchase,
    permissionViewShipment,
    permissionReport
  } = useContext(RootContext);


  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/Purchase/GetQueueCnt?id=' + window.sessionStorage.getItem('authId'),
      );
      setPurchaseQueueCnt(result.data.data[0].cnt);
    };
    fetchData();
  }, [updateListItem]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/GetQueueCnt',
      );
      setShipmentQueueCnt(result.data.data[0].cnt);
    };
    fetchData();
  }, [updateListItem]);

  return (
    <Fragment>
      {/* <ListItem button>
      <ListItemIcon>
        <StorageIcon />
      </ListItemIcon>
      <ListItemText primary="法寶資料庫管理" />
    </ListItem> */}
      {permissionViewBook ? <ListItemLink to="/PrivateHome108" primary="法寶資料庫管理" icon={<StorageIcon />} /> : ''}
      {permissionViewPurchase ? <ListItemLink to="/PrivatePurchase108" primary="進貨管理" queueCnt={purchaseQueueCnt} icon={<ShoppingCartIcon />} /> : '' }
      {permissionViewShipment ? <ListItemLink to="/PrivateShipment108" primary="出貨管理" queueCnt={shipmentQueueCnt} icon={<LocalShippingIcon />} /> : '' }
      {permissionReport ? <ListItemLink to="/PrivateReport108" primary="報表" icon={<BarChartIcon />} /> : '' }
      {/* <ListItemLink to="/" primary="人員管理" icon={<PeopleIcon />} />
       */}
    </Fragment>
  );
}


export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);

export { MainListItems };