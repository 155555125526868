import React, { Fragment, useState, useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableCell, TableHead, TableRow, ButtonGroup, FormControl, FormHelperText } from '@mui/material';
import { Button, TextField, Select, MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { BookManageContext } from './BookManageContext';
import Simplert from "../../components/Simplert";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     color: 'black',
//   },
//   tableHead: {
//     backgroundColor: '#cad8e3'
//   },
// }));

const DynamicRows = () => {
  const {
    dynamicData,
    setDynamicData,
    rowDataId,
    location
  } = useContext(BookManageContext);

  const nullBookData = [];
  const [lgortData, setLgortData] = useState(nullBookData);

    // for Simplert
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMsg, setAlertMsg] = useState('');

  const fetchData = async (id) => {
    const result = await axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetItems?id=' + id,
    );
    setDynamicData(result.data.data);
  };

  const fetchLgortData = async (loc) => {
    const result = await axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLgort?loc=' + loc + "&id=" + window.sessionStorage.getItem('authId'),
    ).then((rtn) => {
      setLgortData(rtn.data.data);

      if (rowDataId !== "") {
        fetchData(rowDataId);
      }
    });
  };

  useEffect(() => {
    fetchLgortData(location);
  }, [location]);



  const handleInputChange = event => {
    const _tmpRows = [...dynamicData];
    _tmpRows[event.target.dataset.id][event.target.name] = event.target.value;
    setDynamicData(_tmpRows);
  };

  const handleAddRow = () => {
    setDynamicData(prevData => [...prevData, { id: '', benefactor: '', qty: '0' }]);
  };
  const handleRemoveRow = () => {
    // this.setState({
    //   rows: this.state.rows.slice(0, -1)
    // });
    //alert(dynamicData[1].name);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const _tmpRows = [...dynamicData];
    _tmpRows.splice(idx, 1);
    setDynamicData(_tmpRows);
  }

  const handleLgortSelectChange = (index) => event => {
    const _tmpRows = [...dynamicData];
    _tmpRows[index][event.target.name] = event.target.value;
    setDynamicData(_tmpRows);
  };

  const handleLgortSelectOpen = () => {
    if (lgortData.length === 0) {
      setAlertType('warning');
      setAlertTitle('請先選擇道場別');
      setShowAlert(true);
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row clearfix">
          <div className="col-md-12 column">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead sx={{backgroundColor: '#cad8e3'}}>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>儲位</TableCell>
                    <TableCell>庫存量</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dynamicData.map((item, index) => (
                    <TableRow id="addr0" key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            // 要給予初始值"", 不然會發生下列錯誤, 導致第一項沒有對應的MenuItem
                            // Material-UI: A component is changing the controlled value state of Select to be uncontrolled
                            value={item.lgort || ""}
                            name="lgort"
                            onChange={handleLgortSelectChange(index)}
                            onOpen={handleLgortSelectOpen}
                          >
                            {lgortData.map((lgortDataItem, i) =>
                              <MenuItem key={i} value={lgortDataItem.lgort}>
                                {lgortDataItem.lgort}
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField value={item.qty}
                          type="number"
                          name="qty"
                          placeholder='必填'
                          inputProps={{ 'data-id': index }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleInputChange}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell>
                        <Button onClick={handleRemoveSpecificRow(index)}>刪除</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button onClick={handleAddRow}>
              新增庫存明細
            </Button>

            <Simplert
              showSimplert={showAlert}
              type={alertType}
              title={alertTitle}
              message={alertMsg}
              customCloseBtnText='關閉'
              onClose={() => setShowAlert(!showAlert)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DynamicRows;
