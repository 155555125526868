import { React, useState, useEffect, useContext, useRef, Fragment } from "react";
import { Dialog, DialogTitle } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import { FormControl, InputAdornment } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Button, TextField, Select, MenuItem, InputLabel } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { BookManageContext } from './BookManageContext';
import Simplert from "../../components/Simplert";

import SaveIcon from '@mui/icons-material/Save';
import DynamicRows from "./DynamicRows";

const StyledDialogTitle = styled(DialogTitle)({
  background: '#125887',
  color: 'white'
});

const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset'
  }
});

// const useStyles = makeStyles({
//   title: {
//     background: '#125887',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   formStyle: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
// });

const RowDataDialog = (props) => {
  const {
    dialogOpen = false,
    row = [],
    onClose = () => { },
    onUpdate = () => { },
    title = "My Dialog",
    buttonText = "Save"
  } = props;

  const {
    emptyBenefactor,
    dynamicData,
    setDynamicData,
    locationList,
    location,
    setLocation
  } = useContext(BookManageContext);

  const [isPrivate, setIsPrivate] = useState(false);

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const categoryRef = useRef();
  const nameRef = useRef();
  const isbnRef = useRef();
  const priceRef = useRef();
  const safeQtyRef = useRef();
  const remarkRef = useRef();

  useEffect(() => {
    if (dialogOpen) {
      setLocation(row.location);

      if (row.isPrivate === '1')
        setIsPrivate(true);
      else
        setIsPrivate(false);
    }

  }, [dialogOpen]);

  const handleAddNewRow = () => {
    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/',
        {
          'location': location,
          'category': categoryRef.current.value,
          'name': nameRef.current.value,
          'isbn': isbnRef.current.value,
          'price': priceRef.current.value,
          'safeQty': safeQtyRef.current.value,
          'remark': remarkRef.current.value,
          'isPrivate': isPrivate,
          'subItem': dynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('新增成功');
          onUpdate();
        }
        setShowAlert(true);
      });
    };
    postData();
  };

  const handleUpdateRow = () => {
    const putData = async () => {
      const result = await axios.put(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/' + row.id,
        {
          'location': location,
          'category': categoryRef.current.value,
          'name': nameRef.current.value,
          'isbn': isbnRef.current.value,
          'price': priceRef.current.value,
          'safeQty': safeQtyRef.current.value,
          'remark': remarkRef.current.value,
          'isPrivate': isPrivate,
          'subItem': dynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('儲存成功');
          setAlertMsg('');
          onUpdate();
        }
        setShowAlert(true);
      });
    };
    putData();
  };

  const handleCloseClick = (mode) => {
    // 按取消後，必須先將dynamicData清空回原始狀態，否則會出錯
    setDynamicData(emptyBenefactor);

    // 儲存成功或按取消鍵才會把父dialog關掉
    if (alertType == 'success' || mode == 'cancel')
      onClose();
  }

  const handleLocationSelectChange = event => {
    setLocation(event.target.value);
  };

  const handlePrivateChange = event => {
    setIsPrivate(!isPrivate);
  };

  return (
    <Fragment>
      <Dialog onClose={onClose} open={dialogOpen}>
        <StyledDialogTitle>
          {title}
        </StyledDialogTitle>
        <Paper sx={{ padding: "2em" }}>
          <Table size="small">
            <TableBody>
              <StyledTableRow>
                <TableCell>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      道場別
                    </InputLabel>
                    <Select
                      value={location}
                      name="location"
                      onChange={handleLocationSelectChange}
                    //autoWidth
                    //displayEmpty
                    // renderValue={(selected) => {
                    //   if (selected.length === 0) {
                    //     return <em>請選擇</em>;
                    //   } else {
                    //     return selected;
                    //   }

                    // }}
                    >
                      {/* <MenuItem disabled value="">
                        <em>請選擇</em>
                      </MenuItem> */}
                      {locationList.map((item, i) =>
                        <MenuItem key={i} value={item}>
                          {item}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField defaultValue={row.category} label="類別/編號"
                    //onInput={(e) => setDlgCategory(e.target.value)}
                    inputRef={categoryRef}
                    variant="standard"
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <FormControl fullWidth>
                    <TextField defaultValue={row.isbn} label="ISBN 國際標準書號"
                      inputRef={isbnRef}
                      variant="standard"
                    />
                  </FormControl>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell colSpan={3}>
                  <FormControl fullWidth>
                    <TextField defaultValue={row.name} label="書名"
                      inputRef={nameRef}
                      variant="standard"
                    />
                  </FormControl>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell>
                  <TextField label="助印價" defaultValue={row.price} type="number"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: <InputAdornment position="end">元</InputAdornment>
                    }}
                    inputRef={priceRef}
                  />
                </TableCell>
                <TableCell>
                  <TextField label="最低庫存量" defaultValue={row.safeQty} type="number"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={safeQtyRef}
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel control={<Checkbox checked={isPrivate} onChange={handlePrivateChange} />} label="不公開" />
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell colSpan={3}>
                  <FormControl fullWidth>
                    <TextField defaultValue={row.remark} label="備註"
                      inputRef={remarkRef}
                      variant="standard"
                    />
                  </FormControl>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell colSpan={3}>
                  <DynamicRows />
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <div style={{ float: 'right' }}>
            <Box
              sx={{
                '& button': {
                  m: 1,
                },
              }}
            >
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={buttonText === '新增' ? handleAddNewRow : handleUpdateRow}
              >
                {buttonText}
              </Button>
              <Button variant="outlined" onClick={() => handleCloseClick('cancel')}>取消</Button>
            </Box>
          </div>

          <Simplert
            showSimplert={showAlert}
            type={alertType}
            title={alertTitle}
            message={alertMsg}
            customCloseBtnText='關閉'
            onParentClose={() => handleCloseClick('')}
            onClose={() => setShowAlert(!showAlert)}
          />
        </Paper>
      </Dialog>
    </Fragment>
  );
}

export default RowDataDialog;