import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const BookManageContext = React.createContext();
const BookManageContextProvider = ({ children }) => {
  const emptyBenefactor = [
    {
      benefactor: '',
      qty: 0
    }
  ];

  const nullBookData = [];
  const [dynamicData, setDynamicData] = useState(emptyBenefactor);
  const [rowDataId, setRowDataId] = useState("");
  const [locationList, setLocationList] = useState(nullBookData);
  const [location, setLocation] = useState('');

  useEffect(() => {
    const fetchLocationList = async (id) => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLocation',
      );
      setLocationList(result.data.data);
    };
    fetchLocationList();
  }, []);

  const defaultContext = {
    emptyBenefactor,
    // 新增動態功德主用
    dynamicData,
    setDynamicData,
    // 按編輯後，傳入row id並撈取書目資料
    rowDataId,
    setRowDataId,
    location,
    setLocation,
    locationList,
    setLocationList
  };

  return (
    <BookManageContext.Provider value={defaultContext}>
      {children}
    </BookManageContext.Provider>
  );
};

export default BookManageContextProvider;