import React, { useState, useContext, useRef, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../RootContext';
import Simplert from "../../components/Simplert";
import axios from 'axios';

import Background from '../../books-wallpaper-5.jpg';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">

      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SytledDiv = styled('div')(({ theme }) => ({
  margin: theme.spacing(8, 4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const SytledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1)
}));

const StyledImageGrid = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor:
    theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}));

const SytledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2)
}));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '100vh',
//   },
//   image: {
//     backgroundImage: `url(${Background})`,
//     backgroundRepeat: 'no-repeat',
//     backgroundColor:
//       theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],

//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//   },
//   paper: {
//     margin: theme.spacing(8, 4),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

const Login = () => {
  let history = useHistory();

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const accountRef = useRef();
  const passwordRef = useRef();

  const {
    authenticated,
    setAuthenticated,
    setAuthId,
    setAuthName,
    setPermissionViewBook,
    setPermissionEditBook,
    setPermissionAddBook,
    setPermissionViewSecretBook,
    setPermissionBookExcel,
    setPermissionViewPurchase,
    setPermissionAddPurchase,
    setPermissionEditPartOfPurchase,
    setPermissionClosePurchase,
    setPermissionPurchaseExcel,
    setPermissionViewShipment,
    setPermissionAddShipment,
    setPermissionEditPartOfShipment,
    setPermissionCloseShipment,
    setPermissionShipmentExcel,
    setPermissionReport,
    permissionViewBook,
    permissionViewPurchase,
    permissionViewShipment,
  } = useContext(RootContext);

  // 登入成功後，轉址去主頁面
  useEffect(() => {
    if (authenticated) {
      if (permissionViewBook)
        history.push("/PrivateHome108");
      else if (permissionViewPurchase)
        history.push("/PrivatePurchase108");
      else if (permissionViewShipment)
        history.push("/PrivateShipment108");
    }
  }, [authenticated, history])

  // 登入驗證
  const handleLoginClick = (e) => {
    e.preventDefault();     // support press 'enter' to trigger submit
    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Login/Auth',
        {
          'account': accountRef.current.value,
          'password': passwordRef.current.value
        },
      ).then(response => {
        if (response.data.succ) {
          var permission = response.data.data[0].descript.split(',');
          setPermissionViewBook(parseInt(permission[0]));           // 閱覽法藏資料庫
          setPermissionEditBook(parseInt(permission[1]));           // 編輯/刪除法藏資料庫
          setPermissionAddBook(parseInt(permission[2]));            // 建立法藏資料庫
          setPermissionViewSecretBook(parseInt(permission[3]));     // 法本權限
          setPermissionBookExcel(parseInt(permission[4]));          // 法藏資料庫匯出Excel權限

          setPermissionViewPurchase(parseInt(permission[5]));       // 閱覽進貨單
          setPermissionAddPurchase(parseInt(permission[6]));        // 建立進貨單
          setPermissionEditPartOfPurchase(parseInt(permission[7])); // 編輯部分進貨單(填寫：收貨人、實際收貨量)
          setPermissionClosePurchase(parseInt(permission[8]));      // 結案進貨單
          setPermissionPurchaseExcel(parseInt(permission[9]));      // 進貨管理匯出Excel權限

          setPermissionViewShipment(parseInt(permission[10]));        // 閱覽出貨單
          setPermissionAddShipment(parseInt(permission[11]));        // 建立出貨單
          setPermissionEditPartOfShipment(parseInt(permission[12])); // 編輯部分出貨單(填寫：包裝人、寄件人、寄件運送等相關資訊)
          setPermissionCloseShipment(parseInt(permission[13]));      // 結案出貨單
          setPermissionShipmentExcel(parseInt(permission[14]));      // 出貨管理匯出Excel權限

          setPermissionReport(parseInt(permission[15]));              // 報表瀏覽權限

          setAuthId(response.data.data[0].account)
          setAuthName(response.data.data[0].name);
          setAuthenticated(true);
        }
        else {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('登入錯誤');
            setAlertMsg(response.data.message);
            setShowAlert(true);
          }
          else {
            setAlertType('error');
            setAlertTitle('發生錯誤');
            setAlertMsg(response.data.message);
            setShowAlert(true);
          }
        }
      });
    };
    postData();
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <StyledImageGrid item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
        style={{ backgroundColor: '#dbd4d3' }} >
        <SytledDiv>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            印贈經會管理系統
          </Typography>
          <SytledForm noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              //required
              fullWidth
              //id="account"
              label="帳號"
              //name="account"
              //autoComplete="account"
              inputRef={accountRef}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              //required
              fullWidth
              //name="password"
              label="密碼"
              type="password"
              inputRef={passwordRef}
            //id="password"
            //autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <SytledButton
              type="submit"  // support press 'enter' to trigger submit
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLoginClick}
            >
              登&nbsp;&nbsp;&nbsp;&nbsp;入
            </SytledButton>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  忘記密碼?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {'申請帳號'}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </SytledForm>
        </SytledDiv>
      </Grid>

      <Simplert
        showSimplert={showAlert}
        type={alertType}
        title={alertTitle}
        message={alertMsg}
        customCloseBtnText='關閉'
        onClose={() => setShowAlert(!showAlert)}
      />
    </Grid>
  );
}

export default Login;