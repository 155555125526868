import { React, useState, useEffect, useRef, useContext, Link, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Select, Menu, MenuItem } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from 'axios';
import RowData from './RowData.js';
import RowDataDialog from './RowDataDialog';
import ExcelDialog from './ExcelDialog';
import ShipmentContextProvider from './ShipmentContext';
import { RootContext } from '../../RootContext';

import { Edit as EditIcon, Add as AddIcon } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}));

const StyledSelectFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 130
}));

const StyledInputFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 250
}));

const StyledSearchFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1)
}));

const StyledDiv = styled('div')({
  '& tbody>.MuiTableRow-root:hover': {
    background: '#f5f6fa'
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cad8e3',
    color: theme.palette.common.black,
  }
}));

// const useStyles = makeStyles((theme) => ({
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//     //paddingLeft: theme.spacing(1),
//     //paddingRight: theme.spacing(1),
//   },
//   table: {
//     '& tbody>.MuiTableRow-root:hover': {
//       background: '#f5f6fa',
//     }
//   },
//   detailHead: {
//     '& > *': {
//       fontWeight: 'bold',
//       borderBottom: 'unset',
//     },
//   },
//   searchFormControl: {
//     margin: theme.spacing(1),
//   },
//   input: {
//     minWidth: 250,
//     margin: theme.spacing(1)
//   },

//   select: {
//     margin: theme.spacing(1),
//     minWidth: 130
//   },

//   addRow: {
//     float: 'right'
//   }
// }));


const Shipment = () => {
  const nullBookData = [];
  const [shipmentData, setShipmentData] = useState(nullBookData);
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const [rowDataDialogOpen, setRowDataDialogOpen] = useState(false);
  const [excelDialogOpen, setExcelDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  const [status, setStatus] = useState("");

  const {
    permissionAddShipment,
    permissionShipmentExcel
  } = useContext(RootContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setStatus(newValue);

    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/GetSearch',
        {
          'searchField': 'status',
          'searchWord': newValue,
          'start': page * rowsPerPage,
          'cnt': rowsPerPage
        },
      ).then(response => {
        if (response.data.succ) {
          setShipmentData(response.data.data);
          setTotalRows(response.data.total);
          setPage(0); // 切換tab，都要將分頁切回第一頁
        }
      });
    };
    postData();
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment?start=' + page * rowsPerPage + '&cnt=' + rowsPerPage,
      );
      setShipmentData(result.data.data);
      setTotalRows(result.data.total);
    };

    // 針對搜尋欄有輸入字串的情況
    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/GetSearch',
        {
          'searchField': searchFieldRef.current.value,
          'searchWord': searchWordRef.current.value,
          'start': page * rowsPerPage,
          'cnt': rowsPerPage
        },
      ).then(response => {
        if (response.data.succ) {
          setShipmentData(response.data.data);
          setTotalRows(response.data.total);
        }
      });
    };

    // 針對狀態tab
    const postData1 = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/GetSearch',
        {
          'searchField': 'status',
          'searchWord': status,
          'start': page * rowsPerPage,
          'cnt': rowsPerPage
        },
      ).then(response => {
        if (response.data.succ) {
          setShipmentData(response.data.data);
          setTotalRows(response.data.total);
        }
      });
    };

    if (searchWordRef.current.value === '' && status == '') // 搜尋欄為空時
      fetchData();
    else if (status !== '')
      postData1();
    else
      postData();

  }, [isDataUpdate, page, rowsPerPage]);

  const searchFieldRef = useRef();
  const searchWordRef = useRef();

  const searchClick = () => {
    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/GetSearch',
        {
          'searchField': searchFieldRef.current.value,
          'searchWord': searchWordRef.current.value,
          'start': page * rowsPerPage,
          'cnt': rowsPerPage
        },
      ).then(response => {
        if (response.data.succ) {
          setShipmentData(response.data.data);
          setTotalRows(response.data.total);
          setPage(0);   // 重新搜尋，都要將分頁切回第一頁
        }
      });
    };
    postData();
    setStatus("");
  }

  const excelClick = () => {
    // const exportData = async () => {
    //   const result = await axios(
    //     {
    //       method: 'GET',
    //       url: process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/ExportExcel',
    //       responseType: 'arraybuffer',
    //     }
    //   ).then(response => {
    //     // const url = window.URL.createObjectURL(new Blob([response.data]));
    //     // const link = document.createElement('a');
    //     // link.href = url;
    //     // link.setAttribute('download', 'template.xlsx');
    //     // document.body.appendChild(link);
    //     // link.click();
    //     var FileSaver = require('file-saver');
    //     var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    //     FileSaver.saveAs(blob, '出貨清單.xlsx');
    //   });
    // };
    // exportData();

    setExcelDialogOpen(!excelDialogOpen);
  }

  return (
    <StyledContainer maxWidth="lg" >
      <StyledSelectFormControl variant="outlined">
        <InputLabel id="demo-simple-select-label">搜尋欄位</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          defaultValue='no'
          label="搜尋欄位"
          size="small"
          inputRef={searchFieldRef}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            //getContentAnchorEl: null
          }}
        >
          <MenuItem value={'no'}>出貨單號</MenuItem>
          <MenuItem value={'name'}>書名</MenuItem>
          <MenuItem value={'shipDate'}>出貨日期</MenuItem>
          <MenuItem value={'application'}>申請方式</MenuItem>
          <MenuItem value={'ship'}>運送方式</MenuItem>
          <MenuItem value={'packer'}>包裝人</MenuItem>
          <MenuItem value={'sender'}>寄件者</MenuItem>
          <MenuItem value={'receiver'}>收件人</MenuItem>
        </Select>
      </StyledSelectFormControl>

      <StyledInputFormControl variant="outlined">
        <TextField
          label="搜尋關鍵字"
          size="small"
          inputRef={searchWordRef}
        >
        </TextField>
      </StyledInputFormControl>

      <StyledSearchFormControl>
        <Tooltip title="查詢">
          <IconButton color="primary" aria-label="upload picture" component="span"
            onClick={searchClick}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </StyledSearchFormControl>
      <StyledSearchFormControl>
        {permissionShipmentExcel ?
          <Button
            variant="outlined"
            onClick={excelClick}
          >
            匯出Excel
          </Button>
          : ''
        }
      </StyledSearchFormControl>
      {permissionAddShipment ?
        <FormControl sx={{ float: 'right' }}>
          <Tooltip title="建立出貨單">
            <IconButton color="primary" component="span"
              onClick={() => setRowDataDialogOpen(!rowDataDialogOpen)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </FormControl>
        : ''
      }

      <ShipmentContextProvider>
        <RowDataDialog
          dialogOpen={rowDataDialogOpen}
          title="建立出貨單"
          buttonText="建立"
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              setRowDataDialogOpen(!rowDataDialogOpen);
            }
          }}
          onUpdate={() => setIsDataUpdate(!isDataUpdate)}
        >
        </RowDataDialog>
        <ExcelDialog
          dialogOpen={excelDialogOpen}
          title="匯出Excel"
          buttonText="匯出"
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              setExcelDialogOpen(!excelDialogOpen);
            }
          }}>
        </ExcelDialog>
      </ShipmentContextProvider>

      <Box sx={{ width: '100%' }}>
        <Tabs
          value={status}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab value="草稿" label="草稿" />
          <Tab value="結案" label="結案" />
          <Tab value="" label="全部" />
        </Tabs>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <StyledDiv>
              {/* <TableContainer component={Paper}> */}
              <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                <Table stickyHeader size="small" aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell />
                      <StyledTableCell>出貨單號</StyledTableCell>
                      <StyledTableCell>收單日期</StyledTableCell>
                      <StyledTableCell>出貨日期</StyledTableCell>
                      <StyledTableCell>運送方式</StyledTableCell>
                      <StyledTableCell>收件人</StyledTableCell>
                      <StyledTableCell>申請方式</StyledTableCell>
                      <StyledTableCell>包裝人</StyledTableCell>
                      <StyledTableCell>寄件者</StyledTableCell>
                      <StyledTableCell>狀態</StyledTableCell>
                      <StyledTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <ShipmentContextProvider>
                      {shipmentData
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                          <RowData key={row.id} row={row}
                            onUpdate={() => setIsDataUpdate(!isDataUpdate)}
                          />
                        ))}
                    </ShipmentContextProvider>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='每頁顯示筆數'
                showFirstButton={true}
                showLastButton={true}
              />
            </StyledDiv>
          </Paper>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default Shipment;