import React, { Fragment, useState, useEffect, useContext } from "react";
import { Table, TableBody, TableContainer, TableCell, TableHead, TableRow, ButtonGroup, FormControl, FormHelperText } from '@mui/material';
import { Button, TextField, Select, MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { ShipmentContext } from './ShipmentContext';
import { RootContext } from '../../RootContext';
import Simplert from "../../components/Simplert";

const DynamicRows = (props) => {
  const {
    saveEvent = false,
  } = props;

  const {
    permissionAddShipment,
    permissionEditPartOfShipment
  } = useContext(RootContext);

  const {
    dynamicData,
    setDynamicData,
    rowDataId,
    setRowDataId,
    location
  } = useContext(ShipmentContext);

  const nullBookData = [];
  const nullBenefactor = { benefactor: null };
  const nullLgort = { lgort: null };
  const [bookData, setBookData] = useState(nullBookData);
  const [dynamicBenefactor, setDynamicBenefactor] = useState(Array.from({ length: 150 }, () => Array.from({ length: 1 }, () => nullBenefactor)));  // 二維陣列，用於儲存每筆書目獨立的功徳主清單
  const [dynamicLgort, setDynamicLgort] = useState(Array.from({ length: 150 }, () => Array.from({ length: 1 }, () => nullLgort)));
  const [allLgortData, setAllLgortData] = useState(nullBookData);
  const [lgortData, setLgortData] = useState(nullBookData);

  // 用來暫存原本的道場別及書目清單
  const [oriDynamicData, setOriDynamicData] = useState(nullBookData);
  const [oriLocation, setOriLocation] = useState('');

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const fetchBookItemData = async (id) => {
    const result = await axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/GetItems?id=' + id,
    );
    setDynamicData(result.data.data);
  };

  // const fetchAllBenefactorData = async () => {
  //   const result = await axios.get(
  //     process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetBenefactor',
  //   );
  //   setAllBenefactorData(result.data.data);
  // };

  // 功能1: 用於dialog open初始化, 載入書目清單中的每一筆書目所對應的功徳主
  // 功能2: 於挑選書目後做功徳主的連動
  const fetchBenefactorData = async (dynamicDataIndex, bookIdArrayIndex, bookIdArray, currDynamicBenefactor) => {
    const result = await axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetBenefactorWithSelect?bookId=' + bookIdArray[bookIdArrayIndex],
    ).then((rtn) => {
      let copy = [...currDynamicBenefactor];
      let tmpIndex = 0;
      if (bookIdArray.length === 1)  // 用於新增書目時
        tmpIndex = dynamicDataIndex;
      else                           // 用於dialog open初始化
        tmpIndex = bookIdArrayIndex;
      copy[tmpIndex] = Array.from({ length: rtn.data.data.length }, () => nullBenefactor);
      for (var i = 0; i < rtn.data.data.length; i++) {
        copy[tmpIndex][i] = { benefactor: rtn.data.data[i].benefactor };
      }
      setDynamicBenefactor(copy);

      // 用於dialog open初始化
      if (bookIdArray.length > bookIdArrayIndex + 1)
        fetchBenefactorData(dynamicDataIndex, bookIdArrayIndex + 1, bookIdArray, copy); // 遞迴呼叫
    });
  };

  // 用來偵測是否有選擇道場別，並撈取該道場的儲位
  const fetchAllLgortData = async (loc) => {
    const result = await axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLgort?loc=' + loc,
    ).then((rtn) => {
      setAllLgortData(rtn.data.data);
      setLgortData(nullBookData);
    });
  };

  // 於書目挑選功徳主後做儲位的連動
  //const fetchLgortData = async (index, bookId, benefactor) => {
  //const fetchLgortData = async (dynamicDataIndex, bookIdArrayIndex, bookIdArray, benefactorArray, currDynamicLgort) => {
  const fetchLgortData = async (dynamicDataIndex, bookIdArrayIndex, bookIdArray, currDynamicLgort) => {
    const result = await axios.get(
      //process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLgortWithSelect?bookId=' + bookId + '&benefactor=' + benefactor,
      //process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLgortWithSelect?bookId=' + bookIdArray[bookIdArrayIndex] + '&benefactor=' + benefactorArray[bookIdArrayIndex],
      process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLgortWithSelect?bookId=' + bookIdArray[bookIdArrayIndex],
    ).then((rtn) => {
      //setAllLgortData(nullBookData);
      //setLgortData(rtn.data.data);

      let copy = [...currDynamicLgort];
      let tmpIndex = 0;
      if (bookIdArray.length === 1)  // 用於新增書目時
        tmpIndex = dynamicDataIndex;
      else                           // 用於dialog open初始化
        tmpIndex = bookIdArrayIndex;
      copy[tmpIndex] = Array.from({ length: rtn.data.data.length }, () => nullLgort);
      for (var i = 0; i < rtn.data.data.length; i++) {
        copy[tmpIndex][i] = { lgort: rtn.data.data[i].lgort };
      }
      setDynamicLgort(copy);

      // 用於dialog open初始化
      if (bookIdArray.length > bookIdArrayIndex + 1)
        //fetchLgortData(dynamicDataIndex, bookIdArrayIndex + 1, bookIdArray, benefactorArray, copy); // 遞迴呼叫
        fetchLgortData(dynamicDataIndex, bookIdArrayIndex + 1, bookIdArray, copy); // 遞迴呼叫
    });
  };






  // ***************************************************************************************
  useEffect(() => {
    // 儲存原有的道場別
    setOriLocation(location);
    setOriDynamicData(dynamicData);
  }, []);

  useEffect(() => {
    // 當開啟視窗時, dynamicData有讀取進來後, 則載入該書目的功徳主清單
    let _tmpBookIdArray = Array.from({ length: dynamicData.length }, () => '');
    //let _tmpBenefactorArray = Array.from({ length: dynamicData.length }, () => '');
    for (var i = 0; i < dynamicData.length; i++) {
      if (dynamicData[i]['bookId'] !== '') {
        _tmpBookIdArray[i] = dynamicData[i]['bookId'];
        //_tmpBenefactorArray[i] = dynamicData[i]['benefactor'];
      }
    }
    //fetchBenefactorData(0, 0, _tmpBookIdArray, dynamicBenefactor);
    fetchLgortData(0, 0, _tmpBookIdArray, dynamicLgort);
  }, [dynamicData.length])

  useEffect(() => {
    const fetchData = async (loc, isGetItem) => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetBookData?loc=' + loc,
      );
      setBookData(result.data.data);

      // 撈dynamicData之前，必須先將bookData撈出來，否則select在assign value時，會對應不到正確的MenuItem
      // 因此要寫在axios.get.then()之中
      if (rowDataId !== "" && isGetItem) {
        fetchBookItemData(rowDataId);
        //fetchBenefactorData();
      }
    };
    if (oriLocation !== '' && location !== oriLocation) {
      setDynamicData(nullBookData);
      fetchData(location, false);
    } else {
      setDynamicData(oriDynamicData);
      fetchData(location, true);
    }
  }, [location]);

  useEffect(() => {
    //fetchAllBenefactorData();
    fetchAllLgortData(location);
  }, [location]);

  // 當按下儲存(RowDataDialog.js)，確定儲存成功後會執行
  useEffect(() => {
    const _tmpRows = [...oriDynamicData];
    for (var i = 0; i < _tmpRows.length; i++) {
      _tmpRows[i]['id'] = dynamicData[i]['id'];
      _tmpRows[i]['bookId'] = dynamicData[i]['bookId'];
      _tmpRows[i]['bookItemId'] = dynamicData[i]['bookItemId'];
      //_tmpRows[i]['benefactor'] = dynamicData[i]['benefactor'];
      _tmpRows[i]['lgort'] = dynamicData[i]['lgort'];
      _tmpRows[i]['qty'] = dynamicData[i]['qty'];
    }
    setOriDynamicData(_tmpRows);
    setOriLocation(location);
  }, [saveEvent]);
  // ***************************************************************************************



  const handleInputChange = event => {
    const _tmpRows = [...dynamicData];
    _tmpRows[event.target.dataset.id][event.target.name] = event.target.value;
    setDynamicData(_tmpRows);
  };

  const handleSelectChange = (index) => event => {
    const _tmpRows = [...dynamicData];
    //let tmp = event.target.value.split(';');
    //_tmpRows[index]['allField'] = event.target.value;   // 合併欄位
    //_tmpRows[index][event.target.name] = tmp[0];        // 書名
    _tmpRows[index]['bookId'] = event.target.value;       // book id
    setDynamicData(_tmpRows);

    let _tmpBookIdArray = [event.target.value];
    //fetchBenefactorData(index, event.target.value);
    //fetchBenefactorData(index, 0, _tmpBookIdArray, dynamicBenefactor);
    fetchLgortData(index, 0, _tmpBookIdArray, dynamicLgort);
  };

  const handleBenefactorSelectChange = (index) => event => {
    const _tmpRows = [...dynamicData];
    _tmpRows[index]['benefactor'] = event.target.value;
    setDynamicData(_tmpRows);

    let _tmpBookIdArray = [_tmpRows[index]['bookId']];
    let _tmpBenefactorArray = [event.target.value];
    fetchLgortData(index, 0, _tmpBookIdArray, _tmpBenefactorArray, dynamicLgort);
  };

  const handleLgortSelectChange = (index) => event => {
    const _tmpRows = [...dynamicData];
    _tmpRows[index]['lgort'] = event.target.value;
    setDynamicData(_tmpRows);
  };

  const handleAddRow = () => {
    setDynamicData(prevData => [...prevData, { allField: '', id: '', bookId: '', bookItemId: '', category: '', benefactor: '', lgort: '', qty: '0' }]);
  };
  const handleRemoveRow = () => {
    // this.setState({
    //   rows: this.state.rows.slice(0, -1)
    // });
    //alert(dynamicData[1].name);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const _tmpRows = [...dynamicData];
    _tmpRows.splice(idx, 1);
    setDynamicData(_tmpRows);
  }

  const handleSelectOpenCheck = () => {
    if (location === '' || location === undefined) {
      setAlertType('warning');
      setAlertTitle('請先選擇道場別');
      setShowAlert(true);
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row clearfix">
          <div className="col-md-12 column">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table" sx={{ backgroundColor: 'white' }}>
                <TableHead sx={{ backgroundColor: '#cad8e3' }}>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>書名</TableCell>
                    <TableCell>儲位</TableCell>
                    <TableCell>數量</TableCell>
                    <TableCell></TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dynamicData.map((item, index) => (
                    <TableRow id="addr0" key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <FormControl variant="standard">
                          <Select
                            //value={item.allField}
                            value={item.bookId}
                            name="name"
                            onChange={handleSelectChange(index)}
                            onOpen={handleSelectOpenCheck}
                            autoWidth
                            displayEmpty
                            inputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                          // renderValue={(selected) => {
                          //   if (selected.length === 0) {
                          //     return <em>請選擇</em>;
                          //   } else {
                          //     let tmp = selected.split(';');
                          //     return tmp[0];
                          //   }

                          // }}
                          >
                            <MenuItem disabled value="">
                              <em>請選擇</em>
                            </MenuItem>
                            {bookData.map((bookDataItem, i) => (
                              // 為了取到功德主，所以將兩個欄位值合併在一起當value
                              <MenuItem key={i}
                                //value={bookData.name + ';' + bookData.benefactor + ';' + bookData.category + ';' + bookData.isbn}
                                value={bookDataItem.id}
                              >
                                {bookDataItem.category + ' ' + bookDataItem.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            // 要給予初始值"", 不然會發生下列錯誤, 導致第一項沒有對應的MenuItem
                            // Material-UI: A component is changing the controlled value state of Select to be uncontrolled
                            value={item.lgort || ""}
                            name="lgort"
                            onChange={handleLgortSelectChange(index)}
                            inputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                          //onOpen={handleSelectOpenCheck}
                          >
                            {
                              dynamicLgort[index].map((lgortDataItem, i) =>
                                <MenuItem key={i} value={lgortDataItem.lgort}>
                                  {lgortDataItem.lgort}
                                </MenuItem>
                              )
                            }
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField value={item.qty}
                          sx={{ m: 1, minWidth: 70 }}
                          type="number"
                          name="qty"
                          placeholder='必填'
                          inputProps={{ 'data-id': index }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleInputChange}
                          variant="standard"
                          InputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                        />
                      </TableCell>
                      <TableCell><span style={{ color: 'red' }}>{item.msg}</span></TableCell>
                      {permissionAddShipment ?
                        <TableCell>
                          <Button
                            onClick={handleRemoveSpecificRow(index)}
                          >
                            刪除
                          </Button>
                        </TableCell>
                        : ''
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {permissionAddShipment ?
              <Button onClick={handleAddRow}>
                新增書目
              </Button>
              : ''
            }
            {permissionAddShipment ?
              <FormControl>
                <FormHelperText>
                  書名選單如找不到項目，請先至法寶資料庫新增相關書目資料
                </FormHelperText>
              </FormControl>
              : ''
            }
            {/* <Button
            onClick={handleRemoveRow}
            className="btn btn-danger float-right"
          >
              Delete Last Row
          </Button> */}

            <Simplert
              showSimplert={showAlert}
              type={alertType}
              title={alertTitle}
              message={alertMsg}
              customCloseBtnText='關閉'
              onClose={() => setShowAlert(!showAlert)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DynamicRows;
