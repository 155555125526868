import { React, useState, useEffect } from 'react';
import './Simplert.css';

const Simplert = props => {
  const {
    showSimplert = false,
    type = 'success',
    title = 'This is Title',
    message = 'I am message alert',
    onClose = () => {},
    onParentClose = () => {},
    customIconUrl,
    customCloseBtnText,
    customConfirmBtnText,
    useConfirmBtn,
    hideAllButton,
  } = props;

  const [isShowPopup, setShowPopup] = useState(false);
  const classSimplert = "simplert " + (isShowPopup ? " simplert--shown" : "");
  const classBtnConfirm = "simplert__confirm";
  const classBtnClose = "simplert__close";

  useEffect(() => {
    if (showSimplert) {
      setShowPopup(true);
    }
  },
    [showSimplert]
  );

  const handleClose = () => {
    setShowPopup(false);
    onClose();

    // 如果有傳入關閉父視窗的function，則呼叫
    if (onParentClose != null)
      onParentClose();
  };

  let iconDOM = null
  if (type === 'info') {
    iconDOM = (
      <div className='simplert__icon simplert__icon--info'>
        <div className='simplert__line simplert__line--info' />
        <div className='simplert__line simplert__line--info-2' />
      </div>
    )
  } else if (type === 'success') {
    iconDOM = (
      <div className='simplert__icon simplert__icon--success'>
        <div className='simplert__line simplert__line--success' />
        <div className='simplert__line simplert__line--success-2' />
      </div>
    )
  } else if (type === 'warning') {
    iconDOM = (
      <div className='simplert__icon simplert__icon--warning'>
        <div className='simplert__line simplert__line--warning' />
        <div className='simplert__line simplert__line--warning-2' />
      </div>
    )
  } else if (type === 'error') {
    iconDOM = (
      <div className='simplert__icon simplert__icon--error'>
        <div className='simplert__line simplert__line--error' />
        <div className='simplert__line simplert__line--error-2' />
      </div>
    )
  } else if (customIconUrl) {
    iconDOM = (
      <div className='simplert__icon'>
        <img src={customIconUrl} />
      </div>
    )
  }

  return (
    <div
      role='modal'
      className={classSimplert}
      //onClick={closeOverlaySimplert.bind(this)}
    >
      <div className='simplert__content'>
        <div className='simplert__header'>
          <div>{iconDOM}</div>
          <b className='simplert__title'>{title}</b>
        </div>
        <div className='simplert__body'>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        <div className='simplert__footer'>
          {useConfirmBtn && !hideAllButton ? (
            <button
              className={classBtnConfirm}
              //onClick={confirmSimplert.bind(this)}
            >
              {customConfirmBtnText}
            </button>
          ) : (
            ''
          )}

          {!hideAllButton ? (
            <button
              className={classBtnClose}
              onClick={handleClose}
            >
              {customCloseBtnText}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

const DEFAULT_TYPE = 'info'
const DEFAULT_BTN_CLOSE_TEXT = 'Close'
const DEFAULT_BTN_CONFIRM_TEXT = 'Confirm'
// const INVALID_TYPE = 'INVALID_TYPE'

Simplert.defaultProps = {
  // hide/show alert
  showSimplert: false,
  // basic setup
  title: '',
  message: '',
  type: DEFAULT_TYPE, // info (default), success, warning, error
  customClass: '',
  customIconUrl: '',
  // open event
  onOpen: null,
  // close button
  customCloseBtnText: DEFAULT_BTN_CLOSE_TEXT,
  customCloseBtnClass: '',
  onClose: null,
  onParentClose: null,
  onOverlayClose: null,
  // confirm button
  useConfirmBtn: false,
  customConfirmBtnText: DEFAULT_BTN_CONFIRM_TEXT,
  customConfirmBtnClass: '',
  onConfirm: null,
  // disabled overlay
  disableOverlayClick: false,
  hideAllButton: false
}


export default Simplert