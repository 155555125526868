import { React, useState, useEffect, useRef, Fragment, useContext } from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { Box, Paper } from '@mui/material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { FormControl, InputLabel, Button, TextField } from "@mui/material";
import { Select, Menu, MenuItem } from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizaitonProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Simplert from "../../components/Simplert";
import { RootContext } from '../../RootContext';

const StyledDialogTitle = styled(DialogTitle)({
  background: '#125887',
  color: 'white'
});

const ExcelDialog = (props) => {
  const {
    dialogOpen = false,
    row = [],
    onClose = () => { },
    title = "My Dialog",
    buttonText = "Save"
  } = props;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const {
    permissionViewSecretBook
  } = useContext(RootContext);

  const searchFieldRef = useRef();

  const handleClick = () => {
    const exportData = async () => {
      const result = await axios(
        {
          method: 'POST',
          url: process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/ExportExcel',
          data: {
            id: window.sessionStorage.getItem('authId'),
            searchField: searchFieldRef.current.value
          },
          responseType: 'arraybuffer',
        }
      ).then(response => {
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'template.xlsx');
        // document.body.appendChild(link);
        // link.click();
        var FileSaver = require('file-saver');
        var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        FileSaver.saveAs(blob, '法寶資料清單.xlsx');
        onClose();
      });
    };

    exportData();
  }

  const handleCloseClick = () => {
    onClose();
  }

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <Fragment>
      <Dialog onClose={onClose} open={dialogOpen} maxWidth="lg">
        <StyledDialogTitle>
          {title}
        </StyledDialogTitle>
        <Paper sx={{ padding: "2em" }}>
          {/* <DialogContentText id="alert-dialog-description">
            選擇狀態及日期區間
          </DialogContentText> */}
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <FormControl variant="outlined" sx={{ minWidth: 130 }}>
                    <InputLabel id="demo-simple-select-label">搜尋欄位</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue='all'
                      label="搜尋欄位"
                      size="small"
                      inputRef={searchFieldRef}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        //getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value={'all'}>全部</MenuItem>
                      <MenuItem value={'category'}>類別_佛經</MenuItem>
                      {permissionViewSecretBook ? <MenuItem value={'category1'}>類別_法本</MenuItem> : ''}
                      <MenuItem value={'category2'}>類別_善書</MenuItem>
                      <MenuItem value={'category3'}>類別_光碟</MenuItem>
                      <MenuItem value={'category4'}>類別_教材</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div style={{ float: 'right' }}>
            <Box
              sx={{
                '& button': {
                  m: 1,
                },
              }}
            >
              <Button variant="outlined" onClick={handleClick}>
                {buttonText}
              </Button>
              <Button variant="outlined" onClick={handleCloseClick}>取消</Button>
            </Box>
          </div>
        </Paper>

        <Simplert
          showSimplert={showAlert}
          type={alertType}
          title={alertTitle}
          message={alertMsg}
          customCloseBtnText='關閉'
          onClose={handleAlertClose}
        />
      </Dialog>
    </Fragment>
  );
}

export default ExcelDialog;