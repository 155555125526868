import { React, useState, useEffect, useRef, useContext, Fragment } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { FormControl, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Button, TextField } from "@mui/material";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import axios from 'axios';
//import MyDialog from "../../components/MyDialog";
import Simplert from "../../components/Simplert";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import { Edit as EditIcon, DeleteOutline as DelOutlineIcon, Delete as DelIcon, Send as SendIcon } from "@mui/icons-material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import RowDataDialog from './RowDataDialog';
import { RootContext } from '../../RootContext';
import { PurchaseContext } from './PurchaseContext';

const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset'
  }
});

const StyledTypography = styled(Typography)({
  color: '#125887',
  fontSize: 14
});

const StyledDeleteDialogTitle = styled(DialogTitle)({
  background: '#c41b1b',
  color: 'white'
});

const StyledSubmitDialogTitle = styled(DialogTitle)({
  background: '#125887',
  color: 'white'
});

// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
//   delete_confirm_title: {
//     background: '#c41b1b',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   submit_confirm_title: {
//     background: '#125887',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   subtitle: {
//     color: '#125887'
//   }
// });


const RowData = (props) => {
  const {
    row,
    tabStatus,
    onUpdate = () => { }
  } = props;

  const {
    updateListItem,
    setUpdateListItem,
    permissionAddPurchase,
    permissionEditPartOfPurchase
  } = useContext(RootContext);

  const {
    rowDataId,
    setRowDataId
  } = useContext(PurchaseContext);

  const [expandOpen, setExpandOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [submitConfirmOpen, setSubmitConfirmOpen] = useState(false);
  const [verifiedConfirmOpen, setVerifiedConfirmOpen] = useState(false);
  const [rowDataDialogOpen, setRowDataDialogOpen] = useState(false);

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    // 當切換到草稿tab時，預設全部展開
    if (tabStatus === '草稿') {
      setExpandOpen(true);
    }

  }, [tabStatus]);

  const handleDeleteConfirmClose = () => {
    if (showAlert)
      onUpdate();

    setDeleteConfirmOpen(false);
    setShowAlert(false);
  };

  const handleSubmitConfirmClose = () => {
    if (showAlert)
      onUpdate();

    setSubmitConfirmOpen(false);
    setShowAlert(false);
  };

  const handleVerifiedConfirmClose = () => {
    if (showAlert)
      onUpdate();

    setVerifiedConfirmOpen(false);
    setShowAlert(false);
  };

  const handleEdit = (id) => {
    setRowDataId(id);
    setRowDataDialogOpen(!rowDataDialogOpen);
  }

  const handleDelete = (id) => {
    const deleteData = async () => {
      const result = await axios.delete(
        process.env.REACT_APP_API_ENDPOINT + '/api/Purchase/' + id
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('刪除成功');
          setUpdateListItem(!updateListItem);
        }
        setShowAlert(true);
      });
    };
    deleteData();
  };

  const handleSubmit = (id) => {
    const submitData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Purchase/Submit',
        {
          'id': id,
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('已送審成功');
          // 觸發更新進貨管理待處理的案件數
          setUpdateListItem(!updateListItem);
        }
        setShowAlert(true);
      });
    };
    submitData();
  };

  // const handleVerified = (id) => {
  //   const submitData = async () => {
  //     const result = await axios.post(
  //       process.env.REACT_APP_API_ENDPOINT + '/api/Purchase/Verified',
  //       {
  //         'id': id,
  //       },
  //     ).then(response => {
  //       if (!response.data.succ) {
  //         if (response.data.code === '200') {
  //           setAlertType('warning');
  //           setAlertTitle('錯誤訊息');
  //           setAlertMsg(response.data.message);
  //         } else {
  //           setAlertType('error');
  //           setAlertTitle('系統發生錯誤');
  //           setAlertMsg(response.data.message);
  //         }
  //       } else {
  //         setAlertType('success');
  //         setAlertTitle('結案成功');
  //         // 觸發更新進貨管理待處理的案件數
  //         setUpdateListItem(!updateListItem);
  //       }
  //       setShowAlert(true);
  //     });
  //   };
  //   submitData();
  // };

  const popupDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  }

  const popupSubmitConfirm = () => {
    setSubmitConfirmOpen(true);
  }

  const popupVerifiedConfirm = () => {
    setVerifiedConfirmOpen(true);
  }

  return (
    <Fragment>
      <StyledTableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExpandOpen(!expandOpen)}
          >
            {expandOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.no}
        </TableCell>
        <TableCell>{row.createDate}</TableCell>
        <TableCell><FormControl sx={{ minWidth: 90 }}>{row.creator}</FormControl></TableCell>
        <TableCell><FormControl sx={{ minWidth: 90 }}>{row.source}</FormControl></TableCell>
        <TableCell><FormControl sx={{ minWidth: 90 }}>{row.receiver}</FormControl></TableCell>
        {/* <TableCell>{row.copyeditor}</TableCell> */}
        <TableCell><FormControl sx={{ minWidth: 70 }}>{row.status}</FormControl></TableCell>
        <TableCell>
          {row.status === '草稿' ?
            <Fragment>
              {permissionAddPurchase || permissionEditPartOfPurchase ?
                <Tooltip title="編輯">
                  <IconButton size="small" onClick={() => handleEdit(row.id)}>
                    <EditIcon></EditIcon>
                  </IconButton>
                </Tooltip>
                : ''
              }
              {/* <Tooltip title="送審">
                <IconButton size="small" onClick={popupSubmitConfirm}>
                  <SendIcon></SendIcon>
                </IconButton>
              </Tooltip> */}
              {permissionAddPurchase ?
                <Tooltip title="刪除">
                  <IconButton size="small" onClick={popupDeleteConfirm}>
                    <DelOutlineIcon></DelOutlineIcon>
                  </IconButton>
                </Tooltip>
                : ''
              }
            </Fragment>
            // : row.status === '審核中' ?
            //   <Fragment>
            //     <Tooltip title="編輯">
            //       <IconButton size="small" onClick={() => handleEdit(row.id)}>
            //         <EditIcon></EditIcon>
            //       </IconButton>
            //     </Tooltip>
            //     <Tooltip title="結案">
            //       <IconButton size="small" onClick={popupVerifiedConfirm}>
            //         <AssignmentTurnedInIcon></AssignmentTurnedInIcon>
            //       </IconButton>
            //     </Tooltip>
            //   </Fragment>
            //   : ''
            :
            permissionAddPurchase ?
              <Tooltip title="編輯">
                <IconButton size="small" onClick={() => handleEdit(row.id)}>
                  <EditIcon></EditIcon>
                </IconButton>
              </Tooltip>
              : ''
          }
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={expandOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                boxShadow: 1,
                borderRadius: 1,
                p: 2,
                bgcolor: '#f5f6fa'
              }}
            >
              {row.remark != '' ?
                <Fragment>
                  <StyledTypography gutterBottom component="div">
                    備註
                  </StyledTypography>
                  <Table size="small" aria-label="purchases">
                    <TableBody>
                      <TableRow><TableCell>{row.remark}</TableCell></TableRow>
                    </TableBody>
                  </Table>
                </Fragment>
                : ""
              }
              <StyledTypography gutterBottom component="div">
                書目資料
              </StyledTypography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>類別/編號</TableCell>
                    <TableCell>書名</TableCell>
                    <TableCell>儲位</TableCell>
                    <TableCell>預計進貨量</TableCell>
                    <TableCell>實際收貨量</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item, index) => (
                    <TableRow id="addr0" key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.lgort}</TableCell>
                      <TableCell>{item.estimatedQty}</TableCell>
                      <TableCell>{item.qty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <RowDataDialog
        dialogOpen={rowDataDialogOpen}
        row={row}
        title="編輯進貨單"
        buttonText="儲存"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setRowDataDialogOpen(!rowDataDialogOpen);
          }
        }}
        onUpdate={onUpdate}
      >
      </RowDataDialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleDeleteConfirmClose();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDeleteDialogTitle id="alert-dialog-title">
          {"刪除"}
        </StyledDeleteDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            確定要刪除此筆資料嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<DelIcon />}
            variant="outlined" onClick={() => handleDelete(row.id)}>
            確定
          </Button>
          <Button variant="outlined" onClick={handleDeleteConfirmClose}>取消</Button>
        </DialogActions>

        <Simplert
          showSimplert={showAlert}
          type={alertType}
          title={alertTitle}
          message={alertMsg}
          customCloseBtnText='關閉'
          onClose={handleDeleteConfirmClose}
        />
      </Dialog>

      <Dialog
        open={submitConfirmOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleSubmitConfirmClose();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledSubmitDialogTitle id="alert-dialog-title">
          {"送審"}
        </StyledSubmitDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            確定要送審此筆資料嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<SendIcon />}
            variant="outlined" onClick={() => handleSubmit(row.id)}>
            確定
          </Button>
          <Button variant="outlined" onClick={handleSubmitConfirmClose}>取消</Button>
        </DialogActions>

        <Simplert
          showSimplert={showAlert}
          type={alertType}
          title={alertTitle}
          message={alertMsg}
          customCloseBtnText='關閉'
          onClose={handleSubmitConfirmClose}
        />
      </Dialog>

      {/* <Dialog
        open={verifiedConfirmOpen}
        onClose={handleVerifiedConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.submit_confirm_title}>
          {"結案"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            確定要結案嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<AssignmentTurnedInIcon />}
            variant="outlined" onClick={() => handleVerified(row.id)}>
            確定
          </Button>
          <Button variant="outlined" onClick={handleVerifiedConfirmClose}>取消</Button>
        </DialogActions>

        <Simplert
          showSimplert={showAlert}
          type={alertType}
          title={alertTitle}
          message={alertMsg}
          customCloseBtnText='關閉'
          onClose={handleVerifiedConfirmClose}
        />
      </Dialog> */}


    </Fragment >
  );
}

export default RowData