import { React, useState, useEffect, useContext, useRef, Fragment } from "react";
import { Dialog, DialogTitle } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import { FormControl, InputAdornment } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Button, TextField, Select, MenuItem, InputLabel } from "@mui/material";
import axios from 'axios';
import Simplert from "../../components/Simplert";

import SaveIcon from '@mui/icons-material/Save';

const StyledDialogTitle = styled(DialogTitle)({
  background: '#125887',
  color: 'white'
});

const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset'
  }
});

const ProfileDialog = (props) => {
    const {
      dialogOpen = false,
      profile = [],
      onClose = () => { },
      onUpdate = () => { },
      title = "My Dialog",
      buttonText = "Save"
    } = props;


    const [account, setAccount] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
  
    // for Simplert
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMsg, setAlertMsg] = useState('');
  
    const accountRef = useRef();
    const nameRef = useRef();
    const pwRef = useRef();
    const confirmPwRef = useRef();
    const telRef = useRef();
    const emailRef = useRef();
  
    // useEffect(() => {
    //   if (dialogOpen) {
    //   }
      
    // }, [dialogOpen]);
  
  
    const handleUpdateRow = () => {
      const putData = async () => {
        const result = await axios.post(
          process.env.REACT_APP_API_ENDPOINT + '/api/Account/UpdateProfile',
          {
            'account': profile.account,
            'pw': pwRef.current.value,
            'confirm': confirmPwRef.current.value,
            'tel': telRef.current.value,
            'email': emailRef.current.value,
          },
        ).then(response => {
          if (!response.data.succ) {
            if (response.data.code === '200') {
              setAlertType('warning');
              setAlertTitle('錯誤訊息');
              setAlertMsg(response.data.message);
            } else {
              setAlertType('error');
              setAlertTitle('系統發生錯誤');
              setAlertMsg(response.data.message);
            }
          } else {
            setAlertType('success');
            setAlertTitle('儲存成功');
            setAlertMsg('');
            onUpdate();
          }
          setShowAlert(true);
        });
      };

      putData();
    };
  
    const handleCloseClick = (mode) => {
      // 儲存成功或按取消鍵才會把父dialog關掉
      if (alertType == 'success' || mode == 'cancel')
        onClose();
    }
  
    return (
      <Fragment>
        <Dialog onClose={onClose} open={dialogOpen} maxWidth="sm">
          <StyledDialogTitle>
            {title}
          </StyledDialogTitle>
          <Paper sx={{ padding: "2em" }}>
            <Table size="small">
              <TableBody>
                <StyledTableRow>
                  <TableCell>
                    <TextField defaultValue={profile.account} label="帳號"
                      inputRef={accountRef}
                      InputProps={{ readOnly: true }}
                      variant="standard"
                      sx={{width: 300}}
                    />
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                <TableCell>
                    <FormControl fullWidth>
                      <TextField defaultValue="" label="新密碼"
                        inputRef={pwRef}
                        variant="standard"
                        type="password"
                      />
                    </FormControl>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField defaultValue="" label="確認新密碼"
                        inputRef={confirmPwRef}
                        variant="standard"
                        type="password"
                      />
                    </FormControl>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField defaultValue={profile.name} label="姓名"
                        inputRef={nameRef}
                        InputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </FormControl>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField defaultValue={profile.tel} label="電話"
                        inputRef={telRef}
                        variant="standard"
                      />
                    </FormControl>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <TextField defaultValue={profile.email} label="Email"
                        inputRef={emailRef}
                        variant="standard"
                      />
                    </FormControl>
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
  
            <div style={{ float: 'right' }}>
              <Box
                sx={{
                  '& button': {
                    m: 1,
                  },
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<SaveIcon />}
                  onClick={handleUpdateRow}
                >
                  {buttonText}
                </Button>
                <Button variant="outlined" onClick={() => handleCloseClick('cancel')}>取消</Button>
              </Box>
            </div>
  
            <Simplert
              showSimplert={showAlert}
              type={alertType}
              title={alertTitle}
              message={alertMsg}
              customCloseBtnText='關閉'
              onParentClose={() => handleCloseClick('')}
              onClose={() => setShowAlert(!showAlert)}
            />
          </Paper>
        </Dialog>
      </Fragment>
    );
  }
  
  export default ProfileDialog;