import React, { Fragment, useState, useEffect, useContext, useMemo, useCallback, useRef } from "react";
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableCell, TableHead, TableRow, ButtonGroup, FormControl, FormHelperText } from '@mui/material';
import { Button, TextField, Select, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { PurchaseContext } from './PurchaseContext';
import { RootContext } from '../../RootContext';
import Simplert from "../../components/Simplert";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     color: 'black',
//   },
//   popper: {
//     width: "fit-content"
//   },
//   tableHead: {
//     backgroundColor: '#cad8e3'
//   },
// }));

const DynamicRows = () => {
  const {
    permissionAddPurchase,
    permissionEditPartOfPurchase
  } = useContext(RootContext);

  const {
    emptyPurchase,
    dynamicData,
    setDynamicData,
    rowDataId,
    setRowDataId,
    location
  } = useContext(PurchaseContext);

  const nullBookData = [];
  const [bookData, setBookData] = useState(nullBookData);
  const [benefactorData, setBenefactorData] = useState(nullBookData);
  const [lgortData, setLgortData] = useState(nullBookData);
  const [tmpBenefactor, setTmpBenefactor] = useState([]);
  const [clearTriger, setClearTriger] = useState(1);

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const refArray = useRef([]);

  const fetchBookItemData = async (id) => {
    const result = await axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/Purchase/GetItems?id=' + id + "&uid=" + window.sessionStorage.getItem('authId'),
    );
    setDynamicData(result.data.data);
  };

  // const fetchBenefactorData = async (id) => {
  //   const result = await axios.get(
  //     process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetBenefactor',
  //   );
  //   setBenefactorData(result.data.data);
  // };

  const fetchLgortData = async (loc) => {
    const result = await axios.get(
      process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetLgort?loc=' + loc + "&id=" + window.sessionStorage.getItem('authId'),
    ).then((rtn) => {
      setLgortData(rtn.data.data);
    });
  };

  useEffect(() => {
    const fetchData = async (loc) => {
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/GetBookData?loc=' + loc + "&id=" + window.sessionStorage.getItem('authId'),
      ).then((rtn) => {
        setBookData(rtn.data.data);

        // 撈dynamicData之前，必須先將bookData撈出來，否則select在assign value時，會對應不到正確的MenuItem
        // 因此要寫在axios.get.then()之中
        if (rowDataId !== "") {
          fetchBookItemData(rowDataId);
          //fetchBenefactorData();
        }

      });
    };
    fetchData(location);
  }, [location]);

  useEffect(() => {
    fetchLgortData(location);
  }, [location]);


  // 用來偵測功徳主欄位是否有被clear
  // useEffect(() => {
  //   // 如果clearTriger == 1 代表是render完第一次執行
  //   if (clearTriger != 1) {
  //     for (var i = 0; i < dynamicData.length; i++) {
  //       // 比對未變動前的功徳主欄位，如值有不一致，則找出index並寫回dynamicData
  //       if (tmpBenefactor[i] != refArray.current[i].value) {
  //         const _tmpRows = [...dynamicData];
  //         _tmpRows[i]['benefactor'] = refArray.current[i].value;
  //         setDynamicData(_tmpRows);
  //       }
  //     }
  //   }
  // }, [clearTriger]);

  const handleInputChange = event => {
    const _tmpRows = [...dynamicData];
    _tmpRows[event.target.dataset.id][event.target.name] = event.target.value;
    setDynamicData(_tmpRows);
  };

  // const handleBenefactorChange = (event, values, reason) => {
  //   if (reason !== 'clear') {
  //     const _tmpRows = [...dynamicData];
  //     _tmpRows[event.target.id.split('-')[0]]['benefactor'] = values;
  //     setDynamicData(_tmpRows);
  //   } else {
  //     //alert(refArray.current[0].getAttribute("name"));

  //     // 藉由clearTriger的變動，來觸發useEffect
  //     setClearTriger(clearTriger + 1);

  //     // 暫存每個功徳主欄位未變動前的值
  //     var tmpArray = [];
  //     for (var i = 0; i < dynamicData.length; i++) {
  //       tmpArray[i] = refArray.current[i].value;
  //     }
  //     setTmpBenefactor(tmpArray);
  //   }
  // }

  const handleSelectChange = (index) => event => {
    const _tmpRows = [...dynamicData];
    let tmp = event.target.value.split(';');
    //_tmpRows[index]['allField'] = event.target.value;     // 合併欄位
    _tmpRows[index]['bookId'] = tmp[0];                   // book id
    //_tmpRows[index][event.target.name] = tmp[1];          // 書名
    setDynamicData(_tmpRows);
  };

  const handleAddRow = () => {
    setDynamicData(prevData => [...prevData, { allField: '', id: '', bookId: '', benefactor: '', lgort: '', estimatedQty: '0', qty: '0' }]);
  };
  const handleRemoveRow = () => {
    // this.setState({
    //   rows: this.state.rows.slice(0, -1)
    // });
    //alert(dynamicData[1].name);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const _tmpRows = [...dynamicData];
    _tmpRows.splice(idx, 1);
    setDynamicData(_tmpRows);
  }

  const handleLgortSelectChange = (index) => event => {
    const _tmpRows = [...dynamicData];
    _tmpRows[index][event.target.name] = event.target.value;
    setDynamicData(_tmpRows);
  };

  const handleSelectOpenCheck = () => {
    if (lgortData.length === 0) {
      setAlertType('warning');
      setAlertTitle('請先選擇道場別');
      setShowAlert(true);
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row clearfix">
          <div className="col-md-12 column">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead sx={{ backgroundColor: '#cad8e3' }}>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>書名</TableCell>
                    {/* <TableCell>功德主</TableCell> */}
                    <TableCell>儲位</TableCell>
                    <TableCell>預計進貨量</TableCell>
                    <TableCell>實際收貨量</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dynamicData.map((item, index) => (
                    <TableRow id="addr0" key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <FormControl variant="standard">
                          <Select
                            //value={item.allField}
                            value={item.bookId}
                            name="name"
                            onChange={handleSelectChange(index)}
                            onOpen={handleSelectOpenCheck}
                            autoWidth
                            displayEmpty
                            inputProps={{ readOnly: permissionEditPartOfPurchase ? true : false }}
                          // renderValue={(selected) => {
                          //   if (selected.length === 0) {
                          //     return <em>請選擇</em>;
                          //   } else {
                          //     let tmp = selected.split(';');
                          //     return tmp[2] + ' ' + tmp[1];
                          //   }

                          // }}
                          >
                            <MenuItem disabled value="">
                              <em>請選擇</em>
                            </MenuItem>
                            {bookData.map((bookDataItem, i) => (
                              // 為了取到功德主，所以將兩個欄位值合併在一起當value
                              <MenuItem key={i}
                                //value={bookDataItem.id + ';' + bookDataItem.name + ';' + bookDataItem.category + ';' + bookDataItem.isbn}
                                value={bookDataItem.id}
                              >
                                {bookDataItem.category + ' ' + bookDataItem.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      {/* <TableCell>
                        <Autocomplete
                          id={index}
                          //ref={ref => {refArray.current[index] = ref;}}
                          //name={"movies" + index}
                          //disableClearable={true}
                          freeSolo
                          onChange={handleBenefactorChange}
                          value={item.benefactor}
                          options={benefactorData.map((option) => option.benefactor)}
                          style={{
                            width: 150,
                          }}
                          renderInput={(params) =>
                            <TextField {...params} variant="standard"
                              inputRef={ref => {
                                refArray.current[index] = ref;
                              }}
                              name="benefactor"
                              inputProps={{ ...params.inputProps, 'data-id': index }}
                              onChange={handleInputChange}
                            />
                          }
                        />
                      </TableCell> */}
                      <TableCell>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            // 要給予初始值"", 不然會發生下列錯誤, 導致第一項沒有對應的MenuItem
                            // Material-UI: A component is changing the controlled value state of Select to be uncontrolled
                            value={item.lgort || ""}
                            name="lgort"
                            onChange={handleLgortSelectChange(index)}
                            onOpen={handleSelectOpenCheck}
                            inputProps={{ readOnly: permissionEditPartOfPurchase ? true : false }}
                          >
                            {lgortData.map((lgortDataItem, i) =>
                              <MenuItem key={i} value={lgortDataItem.lgort}>
                                {lgortDataItem.lgort}
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField value={item.estimatedQty}
                          sx={{ m: 1, minWidth: 70 }}
                          type="number"
                          name="estimatedQty"
                          placeholder='必填'
                          inputProps={{ 'data-id': index }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ readOnly: permissionEditPartOfPurchase ? true : false }}
                          onChange={handleInputChange}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField value={item.qty}
                          sx={{ m: 1, minWidth: 70 }}
                          type="number"
                          name="qty"
                          placeholder='必填'
                          // inputProps={{ readOnly: true, 'data-id': index }}
                          inputProps={{ 'data-id': index }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleInputChange}
                          variant="standard"
                          color={permissionEditPartOfPurchase ? "error" : "primary"}
                          focused={permissionEditPartOfPurchase ? true : false}
                        />
                      </TableCell>
                      {permissionAddPurchase ?
                        <TableCell>
                          <Button
                            onClick={handleRemoveSpecificRow(index)}
                          >
                            刪除
                          </Button>
                        </TableCell>
                        : ''
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {permissionAddPurchase ?
              <Button onClick={handleAddRow}>
                新增書目
              </Button>
              : ''
            }
            {permissionAddPurchase ?
              <FormControl>
                <FormHelperText>
                  書名選單如找不到項目，請先至法寶資料庫新增相關書目資料
                </FormHelperText>
              </FormControl>
              : ''
            }
            {/* <Button
            onClick={handleRemoveRow}
            className="btn btn-danger float-right"
          >
              Delete Last Row
          </Button> */}

            <Simplert
              showSimplert={showAlert}
              type={alertType}
              title={alertTitle}
              message={alertMsg}
              customCloseBtnText='關閉'
              onClose={() => setShowAlert(!showAlert)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DynamicRows;
