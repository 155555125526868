import { React, useState, useEffect, useRef, useContext, Fragment } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { FormControl, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Button, TextField } from "@mui/material";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import axios from 'axios';
//import MyDialog from "../../components/MyDialog";
import Simplert from "../../components/Simplert";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import { Edit as EditIcon, DeleteOutline as DelOutlineIcon, Delete as DelIcon } from "@mui/icons-material";
import RowDataDialog from './RowDataDialog';
import { RootContext } from '../../RootContext';
import { ShipmentContext } from './ShipmentContext';

const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset'
  }
});

const StyledDialogTitle = styled(DialogTitle)({
  background: '#c41b1b',
  color: 'white'
});

const StyledTypography = styled(Typography)({
  color: '#125887',
  fontSize: 14
});

// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
//   title: {
//     background: '#c41b1b',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   subtitle: {
//     color: '#125887'
//   }
// });


const RowData = (props) => {
  const {
    row,
    onUpdate = () => { }
  } = props;

  const {
    updateListItem,
    setUpdateListItem,
    permissionAddShipment,
    permissionEditPartOfShipment
  } = useContext(RootContext);

  const {
    rowDataId,
    setRowDataId
  } = useContext(ShipmentContext);

  const [expandOpen, setExpandOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [rowDataDialogOpen, setRowDataDialogOpen] = useState(false);

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const handleDeleteConfirmClose = () => {
    if (showAlert)
      onUpdate();

    setDeleteConfirmOpen(false);
    setShowAlert(false);
  };

  const handleEdit = (id) => {
    setRowDataId(id);
    setRowDataDialogOpen(!rowDataDialogOpen);
  }

  const handleDelete = (id) => {
    const deleteData = async () => {
      const result = await axios.delete(
        process.env.REACT_APP_API_ENDPOINT + '/api/shipment/' + id
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('刪除成功');
          // 觸發更新出貨管理待處理的案件數
          setUpdateListItem(!updateListItem);
        }
        setShowAlert(true);
      });
    };
    deleteData();
  };

  const popupDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  }


  return (
    <Fragment>
      <StyledTableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExpandOpen(!expandOpen)}
          >
            {expandOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <FormControl sx={{ minWidth: 90 }}>
            {row.no}
          </FormControl>
        </TableCell>
        <TableCell>{row.receiptDate}</TableCell>
        <TableCell>{row.shipDate}</TableCell>
        <TableCell><FormControl sx={{ minWidth: 100 }}>{row.ship}</FormControl></TableCell>
        <TableCell><FormControl sx={{ minWidth: 90 }}>{row.receiver}</FormControl></TableCell>
        <TableCell><FormControl sx={{ minWidth: 100 }}>{row.application}</FormControl></TableCell>
        <TableCell>{row.packer}</TableCell>
        <TableCell>{row.sender}</TableCell>
        <TableCell><FormControl sx={{ minWidth: 70 }}>{row.status}</FormControl></TableCell>
        <TableCell>
          {row.status === '草稿' ?
            <Fragment>
              {permissionAddShipment || permissionEditPartOfShipment ?
                <Tooltip title="編輯">
                  <IconButton size="small" onClick={() => handleEdit(row.id)}>
                    <EditIcon></EditIcon>
                  </IconButton>
                </Tooltip>
                : ''
              }
              {permissionAddShipment ?
                <Tooltip title="刪除">
                  <IconButton size="small" onClick={popupDeleteConfirm}>
                    <DelOutlineIcon></DelOutlineIcon>
                  </IconButton>
                </Tooltip>
                : ''
              }
            </Fragment>
            :
            permissionAddShipment ?
              <Tooltip title="編輯">
                <IconButton size="small" onClick={() => handleEdit(row.id)}>
                  <EditIcon></EditIcon>
                </IconButton>
              </Tooltip>
              : ''
          }
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={expandOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                boxShadow: 1,
                borderRadius: 1,
                p: 2,
                bgcolor: '#f5f6fa'
              }}
            >
              <StyledTypography gutterBottom component="div">
                詳細資料
              </StyledTypography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>收件地址</TableCell>
                    <TableCell>聯絡電話</TableCell>
                    <TableCell>寄送單號</TableCell>
                    <TableCell>箱數</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow id="addr0">
                    <TableCell>{row.destination}</TableCell>
                    <TableCell>{row.receiverTel}</TableCell>
                    <TableCell>{row.shipNo}</TableCell>
                    <TableCell>{row.boxQty}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {row.remark != '' ?
                <Fragment>
                  <StyledTypography gutterBottom component="div">
                    備註
                  </StyledTypography>
                  <Table size="small" aria-label="purchases">
                    <TableBody>
                      <TableRow><TableCell>{row.remark}</TableCell></TableRow>
                    </TableBody>
                  </Table>
                </Fragment>
                : ""
              }
              <StyledTypography gutterBottom component="div">
                書目資料
              </StyledTypography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>類別/編號</TableCell>
                    <TableCell>書名</TableCell>
                    <TableCell>儲位</TableCell>
                    <TableCell>出貨量</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item, index) => (
                    <TableRow id="addr0" key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.lgort}</TableCell>
                      <TableCell>{item.qty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography variant="caption" gutterBottom component="div" align="right">
                {row.createDate}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <RowDataDialog
        dialogOpen={rowDataDialogOpen}
        row={row}
        title="編輯出貨單"
        buttonText="儲存"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setRowDataDialogOpen(!rowDataDialogOpen);
          }
        }}
        //onClose={() => { setRowDataDialogOpen(!rowDataDialogOpen) }}
        onUpdate={onUpdate}
      >
      </RowDataDialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleDeleteConfirmClose();
          }
        }}
        //onClose={handleDeleteConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          {"刪除"}
        </StyledDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            確定要刪除此筆資料嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<DelIcon />}
            variant="outlined" onClick={() => handleDelete(row.id)}>
            確定
          </Button>
          <Button variant="outlined" onClick={handleDeleteConfirmClose}>取消</Button>
        </DialogActions>

        <Simplert
          showSimplert={showAlert}
          type={alertType}
          title={alertTitle}
          message={alertMsg}
          customCloseBtnText='關閉'
          onClose={handleDeleteConfirmClose}
        />
      </Dialog>
    </Fragment >
  );
}

export default RowData