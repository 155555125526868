import { React, useState, useEffect, useRef, Fragment, useContext } from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import { FormControl } from '@mui/material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { InputLabel, Button, TextField, MenuItem, Select } from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizaitonProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import axios from 'axios';
import { RootContext } from '../../RootContext';
import { ShipmentContext } from './ShipmentContext';
import Simplert from "../../components/Simplert";

import SaveIcon from '@mui/icons-material/Save';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DynamicRows from "./DynamicRows";

const StyledDialogTitle = styled(DialogTitle)({
  background: '#125887',
  color: 'white'
});

const StyledSubmitDialogTitle = styled(DialogTitle)({
  background: '#125887',
  color: 'white'
});

const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset'
  }
});

// const useStyles = makeStyles({
//   title: {
//     background: '#125887',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   formStyle: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
// });

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '/' + mm + '/' + dd;

const RowDataDialog = (props) => {
  const {
    dialogOpen = false,
    row = [],
    onClose = () => { },
    onUpdate = () => { },
    title = "My Dialog",
    buttonText = "Save"
  } = props;

  const {
    authId,
    authName,
    updateListItem,
    setUpdateListItem,
    permissionCloseShipment,
    permissionEditPartOfShipment
  } = useContext(RootContext);

  const {
    emptyShipment,
    dynamicData,
    setDynamicData,
    locationList,
    location,
    setLocation
  } = useContext(ShipmentContext);

  //const [shipDate, setShipDate] = useState(today);
  const [shipDate, setShipDate] = useState("");
  //const [receiptDate, setReceiptDate] = useState(today);
  const [receiptDate, setReceiptDate] = useState("");
  const [ship, setShip] = useState("");
  const [region, setRegion] = useState("");
  const [packer, setPacker] = useState("");
  const [sender, setSender] = useState("");
  const [application, setApplication] = useState("");
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [btnSaveText, setBtnSaveText] = useState(buttonText);
  const [rowId, setRowId] = useState(row.id);

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertForVerified, setShowAlertForVerified] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const [verifiedConfirmOpen, setVerifiedConfirmOpen] = useState(false);

  const createDateRef = useRef();
  const creatorRef = useRef();
  const destinationRef = useRef();
  const receiverTelRef = useRef();
  const receiverRef = useRef();
  const shipNoRef = useRef();
  const boxQtyRef = useRef();
  const costRef = useRef();
  //const packerRef = useRef();
  //const senderRef = useRef();
  const emailRef = useRef();
  const remarkRef = useRef();

  // 藉由setLocation 讓DynamicRows.js偵測location變動來觸發撈該筆資料
  useEffect(() => {
    if (dialogOpen) {
      setLocation(row.location);
    }

  }, [dialogOpen]);

  useEffect(() => {
    // 如果是點選編輯，才將row資料寫入state
    if (dialogOpen && row.id !== undefined) {
      setShip(row.ship);
      setApplication(row.application);
      setShipDate(row.shipDate);
      setReceiptDate(row.receiptDate);
      setRegion(row.region);
      setPacker(row.packer);
      setSender(row.sender);
    }
  }, [dialogOpen]);

  const handleAddNewRow = () => {
    const postData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/',
        {
          'createDate': createDateRef.current.value,
          'creator': creatorRef.current.value,
          'creatorId': authId,
          'shipDate': shipDate,
          'receiptDate': receiptDate,
          'destination': destinationRef.current.value,
          'receiver': receiverRef.current.value,
          'ship': ship,
          'shipNo': shipNoRef.current.value,
          'application': application,
          'receiverTel': receiverTelRef.current.value,
          'email': emailRef.current.value,
          'region': region,
          'boxQty': boxQtyRef.current.value,
          'cost': costRef.current.value,
          'location': location,
          'packer': packer,
          'sender': sender,
          'remark': remarkRef.current.value,
          'subItem': dynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('建立成功');
          setAlertMsg("");
          onUpdate();
          setBtnSaveText("儲存");
          setRowId(response.data.message); // 接收剛新增的id
          // 觸發更新出貨管理待處理的案件數
          setUpdateListItem(!updateListItem);
        }
        setShowAlert(true);
      });
    };
    postData();
  };

  const handleUpdateRow = () => {
    const putData = async () => {
      const result = await axios.put(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/' + rowId,
        {
          'createDate': createDateRef.current.value,
          'creator': creatorRef.current.value,
          'creatorId': authId,
          'shipDate': shipDate,
          'receiptDate': receiptDate,
          'destination': destinationRef.current.value,
          'receiver': receiverRef.current.value,
          'ship': ship,
          'shipNo': shipNoRef.current.value,
          'application': application,
          'receiverTel': receiverTelRef.current.value,
          'email': emailRef.current.value,
          'region': region,
          'boxQty': boxQtyRef.current.value,
          'cost': costRef.current.value,
          'location': location,
          'packer': packer,
          'sender': sender,
          'remark': remarkRef.current.value,
          'subItem': dynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('儲存成功');
          setAlertMsg("");
          onUpdate();
          setSaveTrigger(!saveTrigger);
        }
        setShowAlert(true);
      });
    };
    putData();
  };

  const handleVerified = (id) => {
    const submitData = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/Shipment/Verified',
        {
          'id': id,
          'createDate': createDateRef.current.value,
          'creator': creatorRef.current.value,
          'creatorId': authId,
          'shipDate': shipDate,
          'receiptDate': receiptDate,
          'destination': destinationRef.current.value,
          'receiver': receiverRef.current.value,
          'ship': ship,
          'shipNo': shipNoRef.current.value,
          'application': application,
          'receiverTel': receiverTelRef.current.value,
          'email': emailRef.current.value,
          'region': region,
          'boxQty': boxQtyRef.current.value,
          'cost': costRef.current.value,
          'location': location,
          'packer': packer,
          'sender': sender,
          'remark': remarkRef.current.value,
          'subItem': dynamicData
        },
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('結案成功');
          setAlertMsg(response.data.message);
          // 觸發更新出貨管理待處理的案件數
          setUpdateListItem(!updateListItem);
        }
        setShowAlertForVerified(true);
      });
    };

    var flag = false;
    for (var i = 0; i < dynamicData.length; i++) {
      if (dynamicData[i].msg === '庫存不足')
        flag = true;
    }

    if (flag) {
      setAlertType('warning');
      setAlertTitle('錯誤訊息');
      setAlertMsg('有庫存不足之情況，故無法結案');
      setShowAlertForVerified(true);
    }
    else
      submitData();
  };

  const popupVerifiedConfirm = () => {
    setVerifiedConfirmOpen(true);
  }

  const handleVerifiedConfirmClose = () => {
    if (showAlertForVerified)
      onUpdate();

    setVerifiedConfirmOpen(false);
    setShowAlertForVerified(false);

    if (alertTitle === '結案成功')
      handleCloseClick();
  };

  const handleCloseClick = () => {
    // 按取消後，必須先將dynamicData清空回原始狀態，否則會出錯
    setDynamicData(emptyShipment);
    if (buttonText === '建立')
      setBtnSaveText('建立');
    setShip('');
    setApplication('');
    setShipDate('');
    setReceiptDate('');
    setRegion('');
    setPacker('');
    setSender('');
    onClose();
  }

  const handleLocationSelectChange = event => {
    setLocation(event.target.value);
  };

  return (
    <Fragment>
      <Dialog onClose={onClose} open={dialogOpen} fullWidth maxWidth="lg">
        <StyledDialogTitle>
          {title}
        </StyledDialogTitle>
        <Paper sx={{ padding: "2em" }}>
          <Table size="small">
            <TableBody>
              <StyledTableRow>
                <TableCell width="15%">
                  <FormControl variant="standard" fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      道場別
                    </InputLabel>
                    <Select
                      value={location || ""}
                      name="location"
                      onChange={handleLocationSelectChange}
                      inputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                    >
                      {locationList.map((item, i) =>
                        <MenuItem key={i} value={item}>
                          {item}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField defaultValue={btnSaveText === '建立' ? authName : row.creator} label="建立者"
                    inputRef={creatorRef}
                    InputProps={{ readOnly: true }}
                    variant="standard"
                  />
                </TableCell>
                <TableCell>
                  <TextField defaultValue={btnSaveText === '建立' ? today : row.createDate} label="建立日期"
                    inputRef={createDateRef}
                    InputProps={{ readOnly: true }}
                    variant="standard"
                  />
                </TableCell>
                <TableCell>
                  <FormControl sx={{ maxWidth: 195 }}>
                    {/* {permissionEditPartOfShipment ?
                      <TextField defaultValue={receiptDate} label="收單日期"
                        variant="standard"
                        InputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                      />
                      : */}
                    <LocalizaitonProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="收單日期"
                        value={receiptDate}
                        inputFormat="yyyy/MM/dd"
                        mask="____/__/__"
                        onChange={(newValue) => {
                          setReceiptDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} variant="standard" helperText={null} />}
                      />
                    </LocalizaitonProvider>
                    {/* } */}
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl sx={{ maxWidth: 195 }}>
                    <LocalizaitonProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="出貨日期"
                        value={shipDate}
                        inputFormat="yyyy/MM/dd"
                        mask="____/__/__"
                        onChange={(newValue) => {
                          setShipDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} variant="standard" helperText={null} />}
                      />
                    </LocalizaitonProvider>
                  </FormControl>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell>
                  <FormControl variant="standard" sx={{ minWidth: 195 }}>
                    <InputLabel id="demo-simple-select-label">申請方式</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={application}
                      label=""
                      size="small"
                      onChange={(event) => {
                        setApplication(event.target.value);
                      }}
                      inputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        //getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value={'網路索書'}>網路索書</MenuItem>
                      <MenuItem value={'傳真索書'}>傳真索書</MenuItem>
                      <MenuItem value={'mail索書'}>mail索書</MenuItem>
                      <MenuItem value={'劃撥索書'}>劃撥索書</MenuItem>
                      <MenuItem value={'電話索書'}>電話索書</MenuItem>
                      <MenuItem value={'內部索書'}>內部索書</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField defaultValue={row.receiver} label="收件人"
                    inputRef={receiverRef}
                    variant="standard"
                    InputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                  />
                </TableCell>
                <TableCell colSpan={3}>
                  <FormControl fullWidth>
                    <TextField defaultValue={row.destination} label="收件地址"
                      inputRef={destinationRef}
                      variant="standard"
                      InputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                    />
                  </FormControl>
                </TableCell>
              </StyledTableRow>
              <TableRow>
                <TableCell>
                  <FormControl variant="standard" sx={{ minWidth: 195 }}>
                    <InputLabel id="demo-simple-select-label">國內外</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={region}
                      label=""
                      size="small"
                      onChange={(event) => {
                        setRegion(event.target.value);
                      }}
                      inputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        //getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value={'國內'}>國內</MenuItem>
                      <MenuItem value={'國外'}>國外</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField defaultValue={row.receiverTel} label="聯絡電話"
                    inputRef={receiverTelRef}
                    variant="standard"
                    InputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <FormControl fullWidth>
                    <TextField defaultValue={row.email} label="Email"
                      inputRef={emailRef}
                      variant="standard"
                      InputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField defaultValue={row.boxQty}
                    type="number"
                    label="箱數"
                    inputRef={boxQtyRef}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    color={permissionEditPartOfShipment ? "error" : "primary"}
                    focused={permissionEditPartOfShipment ? true : false}
                  />
                </TableCell>
              </TableRow>
              <StyledTableRow>
                <TableCell>
                  <FormControl variant="standard" sx={{ minWidth: 195 }}
                    error={permissionEditPartOfShipment ? true : false}
                    focused={permissionEditPartOfShipment ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">運送方式</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={ship}
                      label=""
                      size="small"
                      onChange={(event) => {
                        setShip(event.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        //getContentAnchorEl: null
                      }}
                    >
                      <MenuItem value={'掛號印刷品'}>掛號印刷品</MenuItem>
                      <MenuItem value={'掛號信函'}>掛號信函</MenuItem>
                      <MenuItem value={'掛號包裹'}>掛號包裹</MenuItem>
                      <MenuItem value={'i郵箱'}>i郵箱</MenuItem>
                      <MenuItem value={'限掛'}>限掛</MenuItem>
                      <MenuItem value={'快捷'}>快捷</MenuItem>
                      <MenuItem value={'國際航空'}>國際航空</MenuItem>
                      <MenuItem value={'國際水陸'}>國際水陸</MenuItem>
                      <MenuItem value={'國際陸空'}>國際陸空</MenuItem>
                      <MenuItem value={'國際快捷'}>國際快捷</MenuItem>
                      <MenuItem value={'嘉里大榮'}>嘉里大榮</MenuItem>
                      <MenuItem value={'DHL'}>DHL</MenuItem>
                      <MenuItem value={'自取'}>自取</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField defaultValue={row.cost}
                    type="number"
                    label="費用"
                    inputRef={costRef}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    color={permissionEditPartOfShipment ? "error" : "primary"}
                    focused={permissionEditPartOfShipment ? true : false}
                  />
                </TableCell>
                <TableCell>
                  <TextField defaultValue={row.shipNo} label="寄送單號"
                    inputRef={shipNoRef}
                    variant="standard"
                    color={permissionEditPartOfShipment ? "error" : "primary"}
                    focused={permissionEditPartOfShipment ? true : false}
                  />
                </TableCell>
                <TableCell>
                  {/* <TextField label="包裝人" defaultValue={row.packer}
                    inputRef={packerRef}
                    variant="standard"
                    color={permissionEditPartOfShipment ? "error" : "primary"}
                    focused={permissionEditPartOfShipment ? true : false}
                  /> */}
                  <FormControl variant="standard" sx={{ minWidth: 195 }}
                    error={permissionEditPartOfShipment ? true : false}
                    focused={permissionEditPartOfShipment ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">包裝人</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={packer}
                      label=""
                      size="small"
                      onChange={(event) => {
                        setPacker(event.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                      }}
                    >
                      <MenuItem value={'鈺雅'}>鈺雅</MenuItem>
                      <MenuItem value={'鈴雅'}>鈴雅</MenuItem>
                      <MenuItem value={'昌志'}>昌志</MenuItem>
                      <MenuItem value={'星宇'}>星宇</MenuItem>
                      {/* <MenuItem value={'佳鈴'}>佳鈴</MenuItem> */}
                      <MenuItem value={'麗因'}>麗因</MenuItem>
                      <MenuItem value={'麗秋'}>麗秋</MenuItem>
                      <MenuItem value={'淑真'}>淑真</MenuItem>
                      <MenuItem value={'凱汶'}>凱汶</MenuItem>
                      <MenuItem value={'珮甄'}>珮甄</MenuItem>
                      <MenuItem value={'自取'}>自取</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  {/* <TextField label="寄件者" defaultValue={row.sender}
                    inputRef={senderRef}
                    variant="standard"
                    color={permissionEditPartOfShipment ? "error" : "primary"}
                    focused={permissionEditPartOfShipment ? true : false}
                  /> */}
                  <FormControl variant="standard" sx={{ minWidth: 195 }}
                    error={permissionEditPartOfShipment ? true : false}
                    focused={permissionEditPartOfShipment ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">寄件者</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={sender}
                      label=""
                      size="small"
                      onChange={(event) => {
                        setSender(event.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                      }}
                    >
                      <MenuItem value={'鈺雅'}>鈺雅</MenuItem>
                      <MenuItem value={'鈴雅'}>鈴雅</MenuItem>
                      <MenuItem value={'昌志'}>昌志</MenuItem>
                      <MenuItem value={'星宇'}>星宇</MenuItem>
                      {/* <MenuItem value={'佳鈴'}>佳鈴</MenuItem> */}
                      <MenuItem value={'芳昇'}>芳昇</MenuItem>
                      <MenuItem value={'麗秋'}>麗秋</MenuItem>
                      <MenuItem value={'珮甄'}>珮甄</MenuItem>
                      <MenuItem value={'淑真'}>淑真</MenuItem>
                      <MenuItem value={'自取'}>自取</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell colSpan={5}>
                  <FormControl fullWidth>
                    <TextField defaultValue={row.remark} label="備註"
                      inputRef={remarkRef}
                      variant="standard"
                      InputProps={{ readOnly: permissionEditPartOfShipment ? true : false }}
                    />
                  </FormControl>
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell colSpan={5}>
                  <DynamicRows saveEvent={saveTrigger} />
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <div style={{ float: 'right' }}>
            <Box
              sx={{
                '& button': {
                  m: 1,
                },
              }}
            >
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={btnSaveText === '建立' ? handleAddNewRow : handleUpdateRow}
              >
                {btnSaveText}
              </Button>
              {(btnSaveText === '建立' || row.status === '結案') ? '' :
                permissionCloseShipment ?
                  <Button
                    variant="outlined"
                    startIcon={<AssignmentTurnedInIcon />}
                    onClick={popupVerifiedConfirm}
                  >
                    結案
                  </Button>
                  : ''
              }
              <Button variant="outlined" onClick={handleCloseClick}>取消</Button>
            </Box>
          </div>

          <Simplert
            showSimplert={showAlert}
            type={alertType}
            title={alertTitle}
            message={alertMsg}
            customCloseBtnText='關閉'
            //onClose={() => setShowAlert(!showAlert)}
            onClose={() => {
              if (alertType === "success") {
                if (buttonText !== '建立')
                  handleCloseClick();
                setShowAlert(!showAlert);
              }
              else
                setShowAlert(!showAlert);
            }}
          />
        </Paper>
      </Dialog>

      <Dialog
        open={verifiedConfirmOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleVerifiedConfirmClose();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledSubmitDialogTitle id="alert-dialog-title">
          {"結案"}
        </StyledSubmitDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            確定要結案嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<AssignmentTurnedInIcon />}
            variant="outlined" onClick={() => handleVerified(rowId)}>
            確定
          </Button>
          <Button variant="outlined" onClick={handleVerifiedConfirmClose}>取消</Button>
        </DialogActions>

        <Simplert
          showSimplert={showAlertForVerified}
          type={alertType}
          title={alertTitle}
          message={alertMsg}
          customCloseBtnText='關閉'
          onClose={handleVerifiedConfirmClose}
        />
      </Dialog>
    </Fragment >
  );
}

export default RowDataDialog;