// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import { React, useState, useContext, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import Button from '@mui/material/Button';
import Layout from './Layout';
import { RootContext } from './RootContext';
import OrderContextProvider from './views/Order/OrderContext';
import ReportContextProvider from './views/Report/ReportContext';
import Login from './views/Login';
import Order from './views/Order';
import Report from './views/Report';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    authenticated,
    setAuthenticated,
    authBody,
    setAuthBody
  } = useContext(RootContext);
  return (
    <Route {...rest} render={(props) => (
      authenticated === true
        ? <Component {...props} />
        : <Redirect to='/PrivateLogin108' />
    )}
    />
  )
}

const ContextRoute = ({ contextComponent, component, ...rest }) => {
  const Provider = contextComponent;
  const Component = component;

  return (
    <Route {...rest} render={(props) => (
      <Provider>
        <Component {...props} />
      </Provider>
    )}
    />
  )
}

function App() {

  return (
    <BrowserRouter>
      <Fragment>
        <Switch>
          {/* <Route exact path='/Home' component={Layout} /> */}
          <Route exact path='/PrivateLogin108' component={Login} />
          <ContextRoute path='/Order' component={Order} contextComponent={OrderContextProvider} />
          <PrivateRoute path='/PrivateHome108' component={Layout} />
          <PrivateRoute path='/PrivatePurchase108' component={Layout} />
          <PrivateRoute path='/PrivateShipment108' component={Layout} />
          {/* <ContextRoute path='/PrivateReport108' component={Report} contextComponent={ReportContextProvider} /> */}
          <PrivateRoute path='/PrivateReport108' component={Layout} />
        </Switch>
      </Fragment>
    </BrowserRouter>
  );
}

export default App;