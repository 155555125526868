import { React, useState, useContext, useEffect, useRef, Fragment } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { FormControl, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Button, TextField } from "@mui/material";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import axios from 'axios';
//import MyDialog from "../../components/MyDialog";
import Simplert from "../../components/Simplert";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Edit as EditIcon, DeleteOutline as DelOutlineIcon, Delete as DelIcon } from "@mui/icons-material";
import RowDataDialog from './RowDataDialog';
import { BookManageContext } from './BookManageContext';
import { RootContext } from '../../RootContext';

const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset',
  }
});

const StyledTypography = styled(Typography)({
  color: '#125887',
  fontSize: 14
});

const StyledDialogTitle = styled(DialogTitle)({
  background: '#c41b1b',
  color: 'white'
});

// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
//   title: {
//     background: '#c41b1b',
//     '& h2': {
//       color: 'white',
//     }
//   },
//   subtitle: {
//     color: '#125887'
//   }
// });


const RowData = (props) => {
  const {
    row,
    onUpdate = () => { },
    isBenefactorColor,
    benefactorName,
  } = props;

  const {
    permissionEditBook
  } = useContext(RootContext);

  const {
    rowDataId,
    setRowDataId,
    setFoundBenefactor
  } = useContext(BookManageContext);

  const [expandOpen, setExpandOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [rowDataDialogOpen, setRowDataDialogOpen] = useState(false);

  // for Simplert
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const handleDeleteConfirmClose = () => {
    if (showAlert)
      onUpdate();

    setDeleteConfirmOpen(false);
    setShowAlert(false);
  };

  const handleEdit = (id) => {
    setRowDataId(id);
    setRowDataDialogOpen(!rowDataDialogOpen);
  }

  const handleDelete = (id) => {
    const deleteData = async () => {
      const result = await axios.delete(
        process.env.REACT_APP_API_ENDPOINT + '/api/FazangBookData/' + id
      ).then(response => {
        if (!response.data.succ) {
          if (response.data.code === '200') {
            setAlertType('warning');
            setAlertTitle('錯誤訊息');
            setAlertMsg(response.data.message);
          } else {
            setAlertType('error');
            setAlertTitle('系統發生錯誤');
            setAlertMsg(response.data.message);
          }
        } else {
          setAlertType('success');
          setAlertTitle('刪除成功');
        }
        setShowAlert(true);
      });
    };
    deleteData();
  };

  const popupDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  }


  return (
    <Fragment>
      <StyledTableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExpandOpen(!expandOpen)}
          >
            {expandOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <FormControl sx={{ minWidth: 50 }}>
            {row.location}
          </FormControl>
        </TableCell>
        <TableCell component="th" scope="row">
          <FormControl sx={{ minWidth: 100 }}>
            {row.category}
          </FormControl>
        </TableCell>
        <TableCell style={{ width: '25rem' }}>
          <FormControl sx={{ minWidth: 120 }}>
            {row.name}
          </FormControl>
        </TableCell>
        {row.qty < row.safeQty ?
          <TableCell align="right"><span style={{ color: 'red' }}>{row.qty}</span></TableCell>
          : <TableCell align="right">{row.qty}</TableCell>}
        <TableCell align="right">{row.safeQty}</TableCell>
        <TableCell>
          {permissionEditBook ?
            <Tooltip title="編輯">
              <IconButton size="small" onClick={() => handleEdit(row.id)}>
                <EditIcon></EditIcon>
              </IconButton>
            </Tooltip>
            : ''
          }
          {permissionEditBook ?
            <Tooltip title="刪除">
              <IconButton size="small" onClick={popupDeleteConfirm}>
                <DelOutlineIcon></DelOutlineIcon>
              </IconButton>
            </Tooltip>
            : ''
          }
          {
            row.isPrivate === '1' ?
              <IconButton size="small" disabled>
                <VisibilityOffIcon></VisibilityOffIcon>
              </IconButton>
              : ''
          }
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={expandOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                boxShadow: 1,
                borderRadius: 1,
                p: 2,
                bgcolor: '#f5f6fa'
              }}
            >
              <StyledTypography gutterBottom component="div">
                詳細資料
              </StyledTypography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '10rem' }}>ISBN</TableCell>
                    <TableCell style={{ width: '10rem' }} align="right">助印價</TableCell>
                    <TableCell>備註</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ width: '10rem' }}>
                      {row.isbn}
                    </TableCell>
                    <TableCell style={{ width: '10rem' }} align="right">{row.price}</TableCell>
                    <TableCell>{row.remark}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <StyledTypography gutterBottom component="div">
                儲位與庫存量對應清單
              </StyledTypography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>儲位</TableCell>
                    <TableCell>庫存量</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item, index) => (
                    <TableRow id="addr0" key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.lgort}</TableCell>
                      {isBenefactorColor && item.benefactor.indexOf(benefactorName) !== -1 && benefactorName !== '' ?
                        <TableCell><span style={{ color: 'red' }}>{item.qty}</span></TableCell>
                        : <TableCell>{item.qty}</TableCell>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <RowDataDialog
        dialogOpen={rowDataDialogOpen}
        row={row}
        title="編輯法寶善書資料"
        buttonText="儲存"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setRowDataDialogOpen(!rowDataDialogOpen);
          }
        }}
        onUpdate={onUpdate}
      >
      </RowDataDialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleDeleteConfirmClose();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          {"刪除"}
        </StyledDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            確定要刪除此筆資料嗎？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<DelIcon />}
            variant="outlined" onClick={() => handleDelete(row.id)}>
            確定
          </Button>
          <Button variant="outlined" onClick={handleDeleteConfirmClose}>取消</Button>
        </DialogActions>

        <Simplert
          showSimplert={showAlert}
          type={alertType}
          title={alertTitle}
          message={alertMsg}
          customCloseBtnText='關閉'
          onClose={handleDeleteConfirmClose}
        />
      </Dialog>
    </Fragment>
  );
}

export default RowData